import React from 'react';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db";
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from '../../context/MarginContext';
import { Slider } from '@mui/material';
import { useTheme } from '../../context/ThemeContext';
function LinksLive() {
  const {sectionMargin,changeLinks} = useTheme();
  const {theme} = useMain();
  const {marginEdit} = useMargin();
    const links = useLiveQuery(async () =>{
      return await db.links.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
    }); 

      const LinkList = ({...item})=>{
        return <div className=" flex items-center space-x-1 ">
                <div dangerouslySetInnerHTML={{__html : item.linkAddress}} className='text-editor '>
               </div>
        </div>
      }
  return (
    <section className="p-2 m-2 text-sm" style={{marginBottom: `${sectionMargin.links}px`}}>
              <div style={{borderColor: theme}}
 className={`border-l-2 border-color-${theme} mb-2`}>
                  <h1 className="bg-slate-300 w-fit p-0.5">LINKS</h1>
                </div>
                <div className='py-2 '>
                {(links)?links?.map((item,index)=>(<LinkList key={index} {...item}/>)):<></>}
                </div>
                {(marginEdit)?(<div className={`w-full h-fit space-y-1 text-xs px-2 text-slate-700 border border-slate-200 print:hidden`}>
                 <Slider
                   onChange={(e)=>changeLinks(e.target.value)}
                  value={sectionMargin.links}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
                <p className='px-1'>Adjust bottom margin {`(${sectionMargin.links} pixels)`}</p>
              </div>):<></>}
    </section>
  )
}
export default LinksLive;