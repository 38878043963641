import React,{useContext ,useState } from 'react';
import { db } from "../models/db";

const LanguagesContext = React.createContext();

export const useLanguages = ()=>{
    return useContext(LanguagesContext);
   }

   export const LanguagesProvider = ({children})=>{

    const [languageName, setLanguageName] = useState('')
    const [isLanguageVisible, setIsLanguageVisible] = useState(true);
    const [level, setLevel] = useState("Advanced");

    const languageVisibility = ()=>{
        return setIsLanguageVisible(!isLanguageVisible);
      }

    const fieldLanguageName = (value)=>{
        return setLanguageName(value);
    }

    const fieldLevel = (value)=>{
        return setLevel(value);
    }

    const submit = async(e)=>{
        e.preventDefault();
        if(languageName==='')
        return;
        await db.languages.add({
        languageName,level,resumeId: parseInt(localStorage.getItem('resume'))
       });
       return setLanguageName('');
    }

    const value = {
        fieldLanguageName,fieldLevel,languageName,submit,languageVisibility,isLanguageVisible
       }
  
        return(
          <LanguagesContext.Provider value={value}>
              {children}
          </LanguagesContext.Provider>
  
        )
  }
