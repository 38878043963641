
import { db } from "../models/db";
import  data  from "../context/FormData.json";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
function TestData() {
    
    const dataDb = ()=>{
        const id = parseInt(localStorage.getItem('resume'));
        db.experience.where('resumeId').equals(id).delete();
        db.education.where('resumeId').equals(id).delete();
        db.libraries.where('resumeId').equals(id).delete();
        db.links.where('resumeId').equals(id).delete();
        db.algorithms.where('resumeId').equals(id).delete();
        db.projects.where('resumeId').equals(id).delete();
        db.highlights.where('resumeId').equals(id).delete();
        db.coding.where('resumeId').equals(id).delete();
        db.languages.where('resumeId').equals(id).delete();
        db.custom.where('resumeId').equals(id).delete();
        db.custom_items.where('resumeId').equals(id).delete();
        db.resume.update(id,{
          name: '',
          title: '',
          email: '',
          phone: '',
          address: ''
        })

        data.experience.map((experience)=>{
          return db.experience.add({
            id: experience.id,
            company:experience.company,
            country: experience.country,
            city: experience.city,
            designation:experience.designation,
            start:experience.start,
            end: experience.end,
            margin: experience.margin,
            resumeId: id 
          })
        })

        data.projects.map((project)=>{
          return db.projects.add({
            id: project.id,
            projectName:project.projectName,
            contributions: project.contributions,
            expId: project.expId,
            resumeId: id
          })
        })

        data.education.map((education)=>{
          return db.education.add({
            id: education.id,
            institution:education.institution,
            country: education.country,
            city: education.city,
            score:education.score,
            degree:education.degree,
            start:education.start,
            end: education.end,
            margin: education.margin,
            resumeId: id 
          })
        })
        
        data.highlights.map((highlight)=>{
          return db.highlights.add({
            id: highlight.id,
            highlightName:highlight.highlightName,
            contributions: highlight.contributions,
            eduId: highlight.eduId,
            resumeId: id
          })
        })

        data.libraries.map((library)=>{
          return db.libraries.add({
            id: library.id,
            libraryName:library.libraryName,
            level: library.level,
            resumeId: id,
          })
        })

        data.languages.map((language)=>{
          return db.languages.add({
            id: language.id,
            languageName:language.languageName,
            level: language.level,
            resumeId: id,
          })
        })

        data.algorithms.map((algorithm)=>{
          return db.algorithms.add({
            id: algorithm.id,
            algorithmName:algorithm.algorithmName,
            level: algorithm.level,
            resumeId: id,
          })
        })

        data.links.map((link)=>{
          return db.links.add({
            id: link.id,
            linkAddress:link.linkAddress,
            eduId: link.eduId,
            resumeId: id,
          })
        })


        db.resume.update(id,{
          name: data.resume.name,
          title: data.resume.title,
          email: data.resume.email,
          phone: data.resume.phone,
          address: data.resume.address
        })
    }
    const addData=  async() => {
        await dataDb();
       }
    
  return (
    <MenuItem onClick={()=>addData()}>
    <ListItemIcon>
      <AssignmentReturnedIcon fontSize="small" />
    </ListItemIcon>
    <ListItemText>Demo</ListItemText>
  </MenuItem>
  )
}

export default TestData;