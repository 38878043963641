import { createTheme } from '@mui/material/styles';

export const themeDark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0d9488',
    },
    secondary: {
      main: '#f50057',
    },
  }
  });
  
  export const themeLight = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#0d9488',
      },
      secondary: {
        main: '#f50057',
      },
    }
    });