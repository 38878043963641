import React from 'react'
import { useExperience } from '../../context/ExperienceContext';
import { usePreview } from '../../context/PreviewContext';
import { useMain } from '../../../main/context/MainContext';
import { useTheme } from '../../context/ThemeContext';
function ExperienceForm() {
  const {changeExperience,sectionMargin} = useTheme();
  const {theme} = useMain();
    const {
           addExperience,toggleExperienceModal,fieldCompany,fieldCity,fieldCountry,
           fieldDesignation,fieldStartdate,fieldEnddate,fieldCurrent,inputEnd,company,country,city,start,end,designation
         } = useExperience();
    const {toggleLiveModal} = usePreview();     
    
    const closeExperienceModal = ()=>{
            toggleLiveModal();
            return toggleExperienceModal();     
         }
         const submitExperience = ()=>{
          toggleLiveModal();
          return addExperience();
         }

  return (
    <div className="fixed z-20 top-0 left-0  h-screen ml-2 mt-18 p-4 border-2 border-slate-300 shadow-2xl bg-white w-1/2 rounded-lg">
      <div className="flex flex-col">
        <div className="">
            <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                Experience add
            </div>
          <div className=" flex flex-col h-fit items-center">
            <div className="modal p-1 w-full max-w-lg h-[512px] space-y-2 overflow-y-scroll">
                <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                  <input onChange={(e)=>{fieldCompany(e.target.value)}} value={company} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Company name"/>
                </div>
                <div className="flex flex-row space-x-2">
                  <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                    <input onChange={(e)=>{fieldCity(e.target.value)}} value={city}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="City"/>
                   </div>
                      <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                        <input onChange={(e)=>{fieldCountry(e.target.value)}} value={country}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Country"/>
                      </div>
                      <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                        <input onChange={(e)=>{fieldDesignation(e.target.value)}} value={designation}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Designation"/>
                      </div>
                </div>
                <div className="w-2/3 p-3 ">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                      Start
                    </label>
                    <input style={{borderColor: theme}} onChange={(e)=>{fieldStartdate(e.target.value)}} value={start}  className={`appearance-none w-full text-gray-700 border-b border-color-${theme} rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white`} type="date"/>
                  </div>
                  <div className="flex space-x-2">
                    <div className="w-2/3 p-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          End
                        </label>
                        <input style={{borderColor: theme}} ref={inputEnd} onChange={(e)=>{fieldEnddate(e.target.value)}} value={end}  className={`appearance-none w-full text-gray-700 border-b border-color-${theme} rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white`} type="date"/>
                        <p className='text-xs px-1 text-slate-700'>Only Year-Month will be displayed</p>

                      </div>
                      <div className="flex items-center w-1/3">
                        <input onChange={(e)=>fieldCurrent(e.target.checked)} className="leading-tight" type="checkbox"/>
                            <span className="ml-2">
                                Current
                            </span>
                      </div>
                  </div>
              </div>
           </div>
             <div className="p-2 w-full flex justify-center item-center space-x-12">
                <button onClick={closeExperienceModal} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                <button style={{backgroundColor: theme}} onClick={submitExperience} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Add</button>
              </div>
              </div>
              </div>  
    </div>
    )
}

export default ExperienceForm