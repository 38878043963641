import React,{useContext ,useState } from 'react';


const PreviewContext = React.createContext();

export const usePreview = ()=>{
    return useContext(PreviewContext);
   }

   export const PreviewProvider = ({children})=>{
    
    const [previewModal, setPreviewModal] = useState(false);
    const [savedInfo, setSavedInfo] = useState(false);
    const [liveModal, setLiveModal] = useState(false);
    const [customModal, setCustomModal] = useState(false);


    function toggleLiveModal(){
      return setLiveModal(!liveModal);
    }
    
    function toggleCustomModal(){
      return setCustomModal(!customModal);
    }
    function toggleSavedInfo(){
      return (savedInfo)?setSavedInfo(false):setSavedInfo(true);
    }

    function togglePreviewModal(){
      return (previewModal)?setPreviewModal(false):setPreviewModal(true);
    }

    const delay = () => new Promise(
      resolve => setTimeout(resolve, 3000)
    );
      
    const value = {
      toggleCustomModal,customModal,togglePreviewModal,previewModal,toggleSavedInfo,savedInfo,delay,toggleLiveModal,liveModal
     }

     
      return(
        <PreviewContext.Provider value={value}>
            {children}
        </PreviewContext.Provider>

      )
}   