import React,{useContext ,useState,useEffect } from 'react';

const ThemeContext = React.createContext();

export const useTheme = ()=>{
    return useContext(ThemeContext);
   }

   export const ThemeCssProvider = ({children})=>{

    const [sectionMargin, setSectionMargin] =  useState({
      personalDetails: 0,
      contact: 0,
      experience: 0,
      education: 0,
      library: 0,
      algorithm:0,
      languages: 0,
      links: 0,
    }) 
    const [marginBar, setMarginBar] = useState(false);
    const [disclaimerModal, setDisclaimerModal] = useState(false);
    const toggleDisclaimerModal = ()=>{
        return setDisclaimerModal(!disclaimerModal);
    }
    

    
      
     const toggleMarginBar = ()=>{
       return setMarginBar(!marginBar);
     }
      

    const changePersonal = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'personalDetails': value}))
    }

    const changeContact = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'contact': value}))  
    }

    const changeExperience = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'experience': value}))
    }

    const changeEducation = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'education': value}))
    }

    const changeLinks = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'links': value}))
    }

    const changeLibrary = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'library': value}))
    }

    const changeLanguages = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'languages': value}))
    }

    const changeAlgorithm = (value)=>{
      setSectionMargin((prev)=> ({...prev, 'algorithm': value}))
    }
    const resetMargin = ()=>{
      return setSectionMargin({
        personalDetails: 0,
        contact: 0,
        experience: 0,
        education: 0,
        library: 0,
        algorithm:0,
        languages: 0,
        links: 0,
      })
    }
    const value = {
      sectionMargin,marginBar,toggleMarginBar,changePersonal,changeContact,changeEducation,
      changeExperience,changeLinks,changeLanguages,changeAlgorithm,changeLibrary,resetMargin,toggleDisclaimerModal,disclaimerModal
    }

      return(
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
      )
}
