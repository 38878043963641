
//Using Reacts context API to make globally available data for Components //
//Please check https://reactjs.org/docs/context.html //
//https://reactjs.org/docs/hooks-reference.html#usecontext//
//This context is for experience part.//

import React,{useContext ,useState,useRef } from 'react';
import  {db}  from '../models/db';
import { usePreview } from './PreviewContext';

const ExperienceContext = React.createContext();


export const useExperience = ()=>{
    return useContext(ExperienceContext);
   }

   export const ExperienceProvider = ({children})=>{
    
    const [experienceModal, setExperienceModal] = useState(false);
    const [experienceEditModal, setExperienceEditModal] = useState(false);
    const [projectsModal, setProjectsModal] = useState(false);
    const [projectsEditModal, setProjectsEditModal] = useState(false);
    const {savedInfo,toggleSavedInfo,delay} = usePreview();

    function toggleExperienceModal(){
      return setExperienceModal(!experienceModal);
    }

    function toggleExperienceEditModal(){
      return setExperienceEditModal(!experienceEditModal);
    }

    function toggleProjectsModal(){
      return setProjectsModal(!projectsModal);
    }
    
    function toggleProjectsEditModal(){
      return setProjectsEditModal(!projectsEditModal);
    }
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [designation, setDesignation] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [projectName, setProjectName] = useState('');
    const [contributions, setContributions] = useState('');
    const [isExperienceVisible, setIsExperienceVisible] = useState(true);
    const inputEnd = useRef();
    const margin = 0;

    const experienceVisibility = ()=>{
      return setIsExperienceVisible(!isExperienceVisible);
    }

    const fieldCompany =(companyValue)=>{
      return setCompany(companyValue);
    }
  
    const fieldCity =(cityValue)=>{
      return setCity(cityValue);
    }
  
    const fieldCountry =(countryValue)=>{
      return setCountry(countryValue);
    }
  
    const fieldDesignation =(designationValue)=>{
      return setDesignation(designationValue);
    }
  
    const fieldStartdate =(startdateValue)=>{
      return setStart(startdateValue);
    }
  
    const fieldEnddate =(enddateValue)=>{
      return setEnd(enddateValue);
    }
  
    const fieldCurrent =(currentValue)=>{
        if(currentValue===true)
        { 
          const attr = document.createAttribute("disabled");
          inputEnd.current.setAttributeNode(attr);
          return setEnd("Present");
        }
        else
          inputEnd.current.removeAttribute("disabled");
       return  setEnd(inputEnd.current.value)
    }

    const fieldProject =(projectValue)=>{
      return setProjectName(projectValue);
    }
  
    const fieldContributions = (data)=>{
      console.log(data);
      setContributions(data);
    }
//Edit Project--------------------------------------------
  const [projectEditName, setProjectEditName] = useState("");
  const [editContributions, setEditContributions] = useState("");

  const fieldEditProject =(projectValue)=>{
    return setProjectEditName(projectValue);
  }

  const fieldEditContributions = (data)=>{
    setEditContributions(data);
  }

//Edit Part---------------------------------------------
    const [editCompany, setEditCompany] = useState("");
    const [editCity, setEditCity] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [editDesignation, setEditDesignation] = useState("");
    const [editStart, setEditStart] = useState();
    const [editEnd, setEditEnd] = useState();
    const [editCurrent, setEditCurrent] = useState();
    const inputEditEnd = useRef();



    const fieldEditCompany =(companyValue)=>{
      return setEditCompany(companyValue);
    }
  
    const fieldEditCity =(cityValue)=>{
      return setEditCity(cityValue);
    }
  
    const fieldEditCountry =(countryValue)=>{
      return setEditCountry(countryValue);
    }
  
    const fieldEditDesignation =(designationValue)=>{
      return setEditDesignation(designationValue);
    }
  
    const fieldEditStartdate =(startdateValue)=>{
      return setEditStart(startdateValue);
    }
  
    const fieldEditEnddate =(enddateValue)=>{
      return setEditEnd(enddateValue);
    }
  
    const fieldEditCurrent =(currentValue)=>{
        if(currentValue===true)
        { 
          const attr = document.createAttribute("disabled");
          inputEditEnd.current.setAttributeNode(attr);
          return setEditEnd("Present");
        }
        else
          inputEditEnd.current.removeAttribute("disabled");
       return  setEditEnd(inputEnd.current.value)
    }
//---------------------------------------------------------
    function closeExperienceModal(){
      return toggleExperienceModal();
    }

    function closeExperienceEditModal(){
      return toggleExperienceEditModal();
    }

    const clearExperienceForm= ()=>{
      setCompany('');
      setCountry('');
      setCity('');
      setDesignation('');
      setStart('');
      setEnd('');
    }
    //Add experience
    const addExperience=  async ()=>{
      try {
         db.experience.add({
          company,country,city,designation,start,end,margin,resumeId: parseInt(localStorage.getItem('resume'))
        }).then((id)=>{
          db.experience.update(id,{order_id: id})
        });
        toggleExperienceModal();
        clearExperienceForm();
      } catch (error) {
        console.error(error);
      }
    }

    //Delete Experience

    const deleteExperience =async (id)=>{
     return db.experience.delete(id)
    .then( () =>{
     db.projects.where('expId').anyOf(id).delete()
    });
    }
    const [expId, setExpId] = useState();

    const changeProjectId =(id)=>{
      return setExpId(id);
    }

    const [projEditId, setProjEditId] = useState();
    const changeProjectEditId = (id)=>{
      setProjEditId(id);
    }

    const fetchExperienceDetails =async(id)=>{
       const data = await db.experience.get(id)
       setExpId(data.id)
      setEditCompany(data.company);
      setEditCity(data.city);
      setEditCountry(data.country);
      setEditDesignation(data.designation);
      setEditStart(data.start);
      setEditEnd(data.end);
      setEditCurrent(data.current);
    }

    const fetchProjectDetails =async(id)=>{
      const data = await db.projects.get(id);
      setProjEditId(id)
      setProjectEditName(data.projectName);
      setEditContributions(data.contributions);
      return toggleProjectsEditModal();
   }
    const updateExperience = async()=>{
       await db.experience.update(expId,{
        company: editCompany,
        city: editCity,
        country: editCountry,
        designation: editDesignation,
        start: editStart,
        end: editEnd
      })

      return toggleExperienceEditModal();
    }

    const updateProjects= async()=>{
      await db.projects.update(projEditId,{
       projectName: projectEditName,
       contributions: editContributions
     })

     return toggleProjectsEditModal();
   }
    const addProjects = async()=>{
      
        return await db.projects.add({
          projectName,contributions,expId
        }).then(()=>{
          setProjectName('');
          setContributions('');
          toggleProjectsModal()
        }).error((error)=>{
          console.error(error);
        })
    }
    
    const value = {
      toggleExperienceModal,closeExperienceModal,toggleExperienceEditModal,closeExperienceEditModal,toggleProjectsModal,
      fieldCompany,fieldCity,fieldCountry,fieldDesignation,fieldStartdate,fieldEnddate,fieldCurrent,fieldProject,addProjects,
      fieldEditCompany,fieldEditCity,fieldEditCountry,fieldEditDesignation,fieldEditStartdate,fieldEditEnddate,fieldEditCurrent,fieldContributions,deleteExperience,
      experienceModal,experienceEditModal,company,country,city,start,end,designation,projectName,inputEnd,
      editCompany,editCity,editCountry,editDesignation,editStart,editEnd,editCurrent,projectsModal,inputEditEnd,contributions,
      addExperience,changeProjectId,fetchExperienceDetails,updateExperience,toggleProjectsEditModal,projectsEditModal,
      changeProjectEditId,projEditId,updateProjects,fetchProjectDetails,fieldEditProject,projectEditName,fieldEditContributions,editContributions,
      isExperienceVisible,experienceVisibility
     }

      return(
        <ExperienceContext.Provider value={value}>
            {children}
        </ExperienceContext.Provider>

      )
}
