import React from 'react';
import ReactQuill from "react-quill";
import { useContact } from '../../context/ContactContext';
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { useMain } from '../../../main/context/MainContext';
import {useTheme} from '../../context/ThemeContext';
import { Slider } from '@mui/material';

function ContactForm() {
   const {changeContact,sectionMargin} = useTheme();
   const {theme} = useMain();
  const {saveContactDetails,closeContactModal,fieldEmail,fieldPhone,fieldAddress,email,address,phone } = useContact();
  return (
    <div className="fixed z-10 top-0 left-0 h-screen ml-2 mt-18 p-4 shadow-2xl bg-white lg:w-1/2 w-full rounded-lg">
        <div className="flex flex-col h-screen">
            <div className="flex flex-col justify-center">
                <div style={{color: theme}}  className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                    Contact details
                </div>
                 <form className="w-full px-16 py-4 flex justify-center flex-col light-mode">
                 <EditorToolbar className='light-mode' toolbarId={'t1'}/>
                 <ReactQuill
                  className='light-mode'
                  theme="snow"
                  value={address}
                  onChange={fieldAddress}
                  placeholder={"Address....."}
                  modules={modules('t1')}
                  formats={formats}
                 />
                  <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} p-1`}>
                    <input autoComplete='off' onChange={(e)=>fieldEmail(e.target.value)} value={email} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Email"/>
                  </div>
                    
                  <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} p-1`}>
                    <input autoComplete='off' onChange={(e)=>fieldPhone(e.target.value)} value={phone} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Phone"/>
                  </div>
                      <div className='w-full h-fit space-y-1 text-slate-700 text-xs  p-1'>
                          <p>Adjust bottom margin {`(${sectionMargin.contact} pixels)`}</p>
                         <Slider
                          onChange={(e)=>changeContact(e.target.value)}
                          value={sectionMargin.contact}
                          min={0}
                          max={500}
                          valueLabelDisplay="auto"
                        />
                      </div>
                  </form>
                </div>
                  <div className="mb-28 w-full flex justify-center item-center space-x-12">
                    <button onClick={()=>closeContactModal()} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                    <button style={{backgroundColor: theme}} onClick={()=>saveContactDetails()} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Save</button>
                  </div>
        </div>
        </div>
  )
}

export default ContactForm;