import React from 'react';
import './App.css';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Projects from "./todo/pages/Projects";
import Sheets from "./todo/pages/Sheets";
import Todo from "./todo/pages/Todo";
import Layout from "./resume/pages/Layout";
import ResumePreview from "./resume/pages/ResumePreview";
import Landing from "./main/components/Landing";
import { CssBaseline } from '@mui/material';
import { useMain } from './main/context/MainContext';
import Home from './resume/pages/Home';
import { themeLight } from './theme';
import Contact from './resume/pages/Contact';
function App() {
  const {themeLight} = useMain();
  return (
    <ThemeProvider theme={themeLight}>
    <CssBaseline/>
      <Router>
        <Switch>
          <Route path="/apps" component={Landing} />
          <Route path="/app/resumebuilder" component={Layout} />
          <Route path="/app/resumepreview" component={ResumePreview} />
          <Route path="/app/todo/projects" component={Projects} />
          <Route path="/app/todo/sheets" component={Sheets} />
          <Route path="/app/todo/todos" component={Todo} />
          <Route path="/app/resume/home" component={Home} />
          <Route path="/app/contact" component={Contact} />
          <Route path="*" component={Landing} />
          <Redirect exact from="/todo" to="/app/todo/projects" />
          <Redirect exact from="/app/todo" to="/app/todo/projects" />
          <Redirect exact from="/apps" to="/apps" />
          <Redirect exact from="/" to="/apps" />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}
export default App;
