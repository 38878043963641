import React, { useState } from 'react'
import { useMain } from '../context/MainContext';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone';
import { IconButton,  Tooltip, Popover, Box} from '@mui/material';
function ThemeSelector() {
    const {colors,changeTheme} = useMain();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      }

    const handleClose = () => {
     setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  return (
    <>
        <Tooltip title='Change theme'>    
        <IconButton aria-describedby={id} onClick={(e)=>handleClick(e)} className='flex flex-col justify-center' color="primary" aria-label="apps icon" component="label">
         <FormatColorFillIcon/>
        </IconButton> 
        </Tooltip>
        <Popover
         id={id}
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className='grid grid-cols-6 gap-2 p-2'>
            {
             colors?.map((color)=>{
                return <div onClick={()=>changeTheme(color)} key={color} style={{backgroundColor: color}} className='relative w-8 h-8 rounded-full'>
                  <div className='absolute top-0 left-0 w-full h-full bg-transparent flex items-center justify-center'>
                   {
                    (localStorage.getItem('theme') === color)?<DoneOutlineTwoToneIcon/>:<></>
                   }
                  </div>
                </div>
             })
            }
        </Box>
      </Popover>
    </>
  )
  
}

export default ThemeSelector;