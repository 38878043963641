import React from 'react'
import { useExperience } from '../../context/ExperienceContext';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import ExperienceList from './ExperienceList';
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from '../../context/MarginContext';
import { PencilFill } from 'react-bootstrap-icons';
import { useTheme } from '../../context/ThemeContext';

function ExperienceLive() 
{
    const {sectionMargin,changeExperience} = useTheme();
    const {theme} = useMain();
    const {marginEdit,toggleMarginEdit} = useMargin();
    const experience = useLiveQuery(() => 
    db.experience.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    );  

      return (
    <section className="p-2 m-2 text-sm "  style={{marginBottom: `${sectionMargin.experience}px`}}>
    <div style={{borderColor: theme}} className={`flex items-center border-l-2 border-color-${theme} mb-2 space-x-1`}>
        <h1 className="bg-slate-300 w-fit p-0.5">EXPERIENCE</h1>
    </div>
    <TransitionGroup>
    {
        (experience)?experience.map((exp,index)=>(
        <CSSTransition key={index} timeout={500} classNames="item">
            <ExperienceList key={index} {...exp}/>
        </CSSTransition>)):<></>
    }
    </TransitionGroup>
</section>
  )
}
export default ExperienceLive;