import React from 'react'
import { useAlgorithms } from '../../../context/AlgortithmsContext';
import { useEducation } from '../../../context/EducationContext';
import { useExperience } from '../../../context/ExperienceContext';
import { useLanguages } from '../../../context/LanguagesContext';
import { useLibraries } from '../../../context/LibrariesContext';
import { useLinks } from '../../../context/LinksContext';
import PersonalDetailsLive from './PersonalDetailsLive';
import ContactLive from './ContactLive';
import LinksLive from './LinksLive';
import ExperienceLive from './ExperienceLive';
import EducationLive from './EducationLive';
import AlgorithmsLive from './AlgorithmsLive';
import LanguageLive from './LanguageLive';
import LibraryLive from './LibraryLive';
import CustomLive from './CustomLive';
import { useMain } from '../../../../main/context/MainContext';
function Resume2() {
  const {isExperienceVisible} = useExperience();
  const {isEducationVisible} =useEducation();
  const {isLibraryVisible} =useLibraries();
  const {isLanguageVisible} =useLanguages();
  const {isAlgorithmVisible} =useAlgorithms();
  const {isLinksVisible} = useLinks();
  const {lineHeight} = useMain();
  return (
    <div style={{lineHeight: `${lineHeight}px`}} className='p-2 m-2 w-full'>
        <PersonalDetailsLive/>
        <div className='flex m-4 border-t border-slate-200 w-full'>
          <div className='w-2/6 border-r border-slate-200'>
            <ContactLive/>
            {(isLinksVisible)?<LinksLive/>:<></>}
            {(isAlgorithmVisible)?<AlgorithmsLive/>:<></>}
            {(isLanguageVisible)?<LanguageLive/>:<></>}
            {(isLibraryVisible)?<LibraryLive/>:<></>}
          </div>
          <div className='w-4/6'>
          {(isExperienceVisible)?<ExperienceLive/>:<></>}
          {(isEducationVisible)?<EducationLive/>:<></>}
          <CustomLive/>
          </div>  
        </div>
    </div>
  )
}

export default Resume2