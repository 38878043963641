import React,{useState} from 'react'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Slider, IconButton,  Tooltip, Popover, Box, Typography } from '@mui/material';
import { useMain } from '../context/MainContext';
function LineHeight() {
    const {lineHeight,changeLineHeight} = useMain();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      }

    const handleClose = () => {
     setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  return (
    <>
        <Tooltip title='Line height'>    
        <IconButton aria-describedby={id} onClick={(e)=>handleClick(e)} className='flex flex-col justify-center' color="primary" aria-label="apps icon" component="label">
         <SortByAlphaIcon/>
        </IconButton> 
        </Tooltip>
        <Popover
         id={id}
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className='p-4'>
        <Typography variant='h6'>
            Adjust line height
        </Typography>
        <Slider
        onChange={(e)=>changeLineHeight(e.target.value)}
        value={lineHeight}
        min={8}
        max={32}
        valueLabelDisplay="auto"
        />
        </Box>
      </Popover>
    </>
  )
}

export default LineHeight