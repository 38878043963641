import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker'
import { AlgorithmsProvider } from './resume/context/AlgortithmsContext';
import { ContactProvider } from './resume/context/ContactContext';
import { EducationProvider } from './resume/context/EducationContext';
import { ExperienceProvider } from './resume/context/ExperienceContext';
import { FormProvider} from "./resume/context/FormContext";
import { LanguagesProvider } from './resume/context/LanguagesContext';
import { LibrariesProvider } from './resume/context/LibrariesContext';
import { LinksProvider } from './resume/context/LinksContext';
import { PreviewProvider } from './resume/context/PreviewContext';
import { CustomProvider } from "./resume/context/CustomContext";
import { ThemeCssProvider } from "./resume/context/ThemeContext";
import { MarginProvider } from "./resume/context/MarginContext";
import { ModalProvider } from './todo/context/ModalContext';
import ThemedSuspense from './main/components/ThemedSuspense'
import { MainProvider } from './main/context/MainContext';
import { ResumeProvider } from './resume/context/ResumeContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MainProvider>
  <ThemeCssProvider>
  <ResumeProvider>
  <ModalProvider>
    <MarginProvider>
    <PreviewProvider>
    <FormProvider>
    <ExperienceProvider>
    <EducationProvider>
    <ContactProvider>
    <LibrariesProvider>
    <LanguagesProvider>
    <AlgorithmsProvider>
    <LinksProvider>
    <CustomProvider>
     <Suspense fallback={<ThemedSuspense/>}>
     <App />
     </Suspense>
    </CustomProvider>
    </LinksProvider>
    </AlgorithmsProvider>
    </LanguagesProvider>
    </LibrariesProvider>
    </ContactProvider>
    </EducationProvider>
    </ExperienceProvider>
    </FormProvider>
    </PreviewProvider>
    </MarginProvider>
    </ModalProvider>
    </ResumeProvider>
    </ThemeCssProvider>
    </MainProvider>
);

serviceWorker.register()
