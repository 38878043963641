import React,{useContext ,useState } from 'react';
import { db } from "../models/db";

const LibrariesContext = React.createContext();

export const useLibraries = ()=>{
    return useContext(LibrariesContext);
   }

   export const LibrariesProvider = ({children})=>{

    const [libraryName, setLibraryName] = useState('')
    const [isLibraryVisible, setIsLibraryVisible] = useState(true);
    const [level, setLevel] = useState("Advanced");

    const libraryVisibility = ()=>{
        return setIsLibraryVisible(!isLibraryVisible);
      }

    const fieldLibraryName = (value)=>{
        return setLibraryName(value);
    }

    const fieldLevel = (value)=>{
        return setLevel(value);
    }

    const submit = async(e)=>{
        e.preventDefault();
        if(libraryName==='')
        return;
        await db.libraries.add({
        libraryName,level,resumeId: parseInt(localStorage.getItem('resume'))
       });


       return setLibraryName('');
    }

    const value = {
        fieldLibraryName,fieldLevel,libraryName,submit,libraryVisibility,isLibraryVisible
       }
  
        return(
          <LibrariesContext.Provider value={value}>
              {children}
          </LibrariesContext.Provider>
  
        )
  }
