import React,{useState,useEffect} from 'react';
import { db } from '../../models/db';
import { useLanguages } from '../../context/LanguagesContext';
import { useLiveQuery } from "dexie-react-hooks";
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../main/context/MainContext';

function Languages() {
    const {theme} = useMain();
    const {fieldLanguageName,fieldLevel,submit,languageName,languageVisibility,isLanguageVisible,langRef} = useLanguages();

    const [advancedCount, setAdvancedCount] = useState(0);
    const [beginnerCount, setBeginnerCount] = useState(0);
    const [intermediateCount, setIntermediateCount] = useState(0);

    const advanced = useLiveQuery(async () => {
      return await db.languages.where('level').equals('Advanced').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).toArray();
    }); 

    const intermediate = useLiveQuery(async () => {
      return await db.languages.where('level').equals('Intermediate').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).toArray();
    }); 

    const beginner = useLiveQuery(async () => {
      return await db.languages.where('level').equals('Beginner').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).toArray();
    }); 

    useEffect(() => {
      db.languages.where('level').equals('Advanced').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).count((count)=>{
        setAdvancedCount(count);
      })
    }, [advanced]);

    useEffect(() => {
      db.languages.where('level').equals('Intermediate').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).count((count)=>{
        setIntermediateCount(count);
      })
    }, [intermediate]);
    
    useEffect(() => {
      db.languages.where('level').equals('Beginner').and((item)=>{
        return item.resumeId === parseInt(localStorage.getItem('resume'))
      }).count((count)=>{
        setBeginnerCount(count);
      })
    }, [beginner]);

    const deleteRecord = (id)=>{
      return db.languages.delete(id);
    }
    const LanguageList = ({...list})=>{
      return <div className="flex items-center justify-between w-fit space-x-1 mb-1  border border-slate-600 text-center rounded-xl px-1 ">
      <p>{list.languageName}</p>
      <svg  onClick={()=>deleteRecord(list.id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`cursor-pointer w-5 h-5 text-color-${theme}`}>
         <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
      </div>
    }
    
    
    
  return (
    <>
        <div  className="flex justify-between items-center m-4 mb-0 p-3 shadow-2xl bg-white">
            <div className='flex'>
            <h1 style={{color: theme}}>Programming languages</h1>
            </div>
                  {
                    (isLanguageVisible)?
                    <svg onClick={languageVisibility} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>:
                    <svg onClick={languageVisibility} xmlns="http://www.w3.org/2000/svg" className="text-teal-700 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  }
          </div>
             
                
           <div className="flex flex-col items-center m-4 mt-0 mb-0 p-3 shadow-2xl bg-white space-x-1">
               
                  { (advancedCount>0)?(
                    <div className="w-full  ">
                 <h1 className="text-sm bg-slate-300 rounded-xl w-fit px-2">Advanced</h1>
                 <TransitionGroup className="flex-container items-center space-x-1 p-2 text-xs">
                  {advanced?advanced.map((item,index)=>(
                    <CSSTransition key={index} timeout={500} classNames="item">
                    <LanguageList key={index} {...item}/>
                    </CSSTransition>
                    )):<></>}
                </TransitionGroup>
            </div>
            ):<></>
              }

              {
                (intermediateCount>0)?(
                    <div className="w-full">
                 <h1 className="text-sm bg-slate-300 rounded-xl w-fit px-2">Intermediate</h1>
                 <TransitionGroup className="flex-container items-center space-x-1 p-2 text-xs">
                  {intermediate?intermediate.map((item,index)=>(
                    <CSSTransition key={index} timeout={500} classNames="item">
                    <LanguageList key={index} {...item}/>
                    </CSSTransition>
                    )):<></>}
                  </TransitionGroup>
            </div>
            ):<></>
              }

              {
                (beginnerCount>0)?(
                    <div className="w-full">
                 <h1 className="text-sm bg-slate-300 rounded-xl w-fit px-2">Beginner</h1>
                 <TransitionGroup className="flex-container items-center space-x-1 p-2 text-xs">
                  {beginner?beginner.map((item,index)=>(
                    <CSSTransition key={index} timeout={500} classNames="item">
                    <LanguageList key={index} {...item}/>
                    </CSSTransition>
                    )):<></>}
                  </TransitionGroup>
            </div>
            ):<></>
              }
            </div>
          
          <div className="flex  items-center m-4 mt-0 mb-0 p-3 shadow-2xl bg-white rounde-xl space-x-1">
          <form className='flex items-center w-full text-xs' onSubmit={(e)=>submit(e)}>
              <div className="relative w-1/3">
                        <select style={{borderColor: theme}} onChange={(e)=>{fieldLevel(e.target.value)}} className={`block appearance-none w-full bg-white border-b border-color-${theme} text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white`}>
                          <option>Advanced</option>
                          <option>Intermediate</option>
                          <option>Beginner</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                       
                      </div>
            <div style={{borderColor: theme}} className={`flex items-center w-full border-b border-color-${theme}`}>
              <input onChange={(e)=>fieldLanguageName(e.target.value)} value={languageName} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-2 px-2 leading-tight focus:outline-none" type="text" placeholder="Language name"/>
            </div>
            <button type='submit' className="text-xs bg-slate-300 rounded-sm w-16 text-center py-1">ADD</button>
            </form>
          </div>
          <div ref={langRef}></div>
    </>
  )
}

export default Languages;