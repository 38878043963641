import { useModal } from '../context/ModalContext';
import React,{useState,useEffect,useRef,useContext} from 'react'
import { db } from "../model/db";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useLiveQuery } from "dexie-react-hooks";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMain } from '../../main/context/MainContext';

function ChildTaskModal() {;
    const{
        toggleChildTaskModal,root,mapChildren,childTaskModal,currentSheet,currentTodo,addTop
      } = useModal();
      const {dark} = useMain();


      const [title, setTitle] = useState('');
      const [note, setNote] = useState('');
      const [brief,setBrief] = useState('');
      const [map, setMap] = useState();

      useEffect(() => {
        if(mapChildren !== undefined)
        {
          setMap(mapChildren);
        }
      },[mapChildren]);
      
          
      const titleRef = useRef();
      const noteRef = useRef();

      

    const changeTitle = (value)=>{
        return setTitle(value);
      }
  
      const changeNote = (value)=>{
        return setNote(value)
      }
      
      const changeBrief = (value)=>{
        return setBrief(value);
      }
      const addTask = async ()=>{
        if(title==='')
        return;

         db.todos.add({
          "title": title,
          "brief": brief,
          "note": note,
          "createdAt": new Date(),
          "projectsId": parseInt(currentSheet),
          "sheetId": parseInt(currentTodo),
          "isCompleted": false,
          "primaryRoot": false,
          "children": [],
          "rootId": root
        }).then((id)=>{
          if(addTop)
          map.splice(0,0,id)
          else
          map.push(id)

          db.todos.update(root,{children: map})
         })
         setNote('');
         setTitle('');
         setBrief('');
        toggleChildTaskModal();
      }
  return (
        <Dialog open={childTaskModal} onClose={toggleChildTaskModal}>
        <DialogTitle>Add task</DialogTitle>
        <DialogContent className='overflow-y-auto space-y-1'>
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            tabIndex={1} 
            ref={titleRef} 
            name='title'
            value={title} 
            onChange={(e)=>changeTitle(e.target.value)}
          />
          <TextField
           id="brief"
           label="Brief"
           multiline
           rows={3}
           variant="standard"
           tabIndex={2}
           ref={noteRef} 
           name='note'  
           value={brief} 
           onChange={(e)=>changeBrief(e.target.value)}
           fullWidth
        />
        <div className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`}>
        <EditorToolbar toolbarId={'t1'} className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`} />
        <ReactQuill tabIndex={3}
          className={`${dark? 'dark-mode': 'light-mode'}`}
          theme="snow"
          value={note}
          onChange={changeNote}
          placeholder={"Details"}
          modules={modules('t1')}
          formats={formats}
         />
         </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleChildTaskModal}>Cancel</Button>
          <Button variant='contained' onClick={()=>addTask()}>Add</Button>
        </DialogActions>
      </Dialog>
  )
}

export default ChildTaskModal;