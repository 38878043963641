import React,{useState} from 'react';
import { db } from "../model/db";
import { useLiveQuery } from "dexie-react-hooks";
import { Link, useHistory } from "react-router-dom";
import { useModal } from '../context/ModalContext';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/material';

function SheetList(props) {
    const sheets =  useLiveQuery(
        () => db.sheets.where('projectsId').equals(props.value).reverse().toArray()
      );
    
    const navigate = useHistory();  

    const {changeCurrentTodo,currentTodo} = useModal();  

    const SheetItem = ({...item})=>{
      const [editSheet, setEditSheet] = useState(false);
      const [editTitle, setEditTitle] = useState(item.title);
      const [deleteModal, setDeleteModal] = useState(false);
  
      const toggleDeleteModal = ()=>{
        return setDeleteModal(!deleteModal);
      }
          const changeEditTitle = (value)=>{
            return setEditTitle(value)
          }

          const updateSheet = async(id,e)=>{
            e.preventDefault();
            if (editTitle === '') return;
             toggleEdit();
            return await db.sheets.update(id,{title: editTitle})
          }

        const toggleEdit = ()=>{
          return setEditSheet(!editSheet)
        }

      const deleteSheet= async (id)=>{
        toggleDeleteModal();
        await db.sheets.delete(id).then(()=>{
          db.todos.where('sheetId').anyOf(id).delete();
        });;
    }
    const directPage = (id)=>{
      changeCurrentTodo(id);
      navigate.push('/app/todo/todos');
    }

    const DeleteDialog = ()=>{
      return (
        <Dialog
                open={deleteModal}
                onClose={toggleDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Delete sheet?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Deleting project will also delete all it's sheets and tasks!<br/>
                      Can not undo .
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={toggleDeleteModal}>Cancel</Button>
                  <Button color='error' onClick={()=>deleteSheet(item.id)} autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
      )
    }
        return(
             <>
              <TableRow>
                  <TableCell>
                    <Box className='relative'>
                    <Typography className='w-full h-full hover:cursor-pointer hover:text-teal-600 hover:underline' 
                      onClick={()=>directPage(item.id)} 
                      display="block"
                      gutterBottom>
                      {item.title}
                    </Typography>
                    {
                      (editSheet)?
                      <Box 
                      component="form" 
                      sx={{bgcolor:'background.default'}} 
                      className='absolute flex items-center top-0 left-0 w-full h-full'
                      onSubmit={(e)=>updateSheet(parseInt(item.id),e)}
                      >
                      <TextField
                       id="edit-sheet" 
                       variant="standard"
                       value={editTitle} 
                       onChange={(e)=>{changeEditTitle(e.target.value)}} 
                       className='px-0.5 w-full h-full'
                       />
                      <Button 
                      onClick={(e)=>updateSheet(parseInt(item.id),e)}
                      type='submit' 
                      variant='contained' 
                      size='small' 
                      color='primary' 
                      className='flex' 
                      aria-label="edit icon" 
                      component="label"
                      >
                       Save
                      </Button>
                      </Box>:<></>
                    }
                    </Box>
                  </TableCell>
                  <TableCell>
                  {new Intl.DateTimeFormat().format(item.createdAt)}
                  </TableCell>
                  <TableCell>
                    <Box className='flex space-x-1'>
                    <Tooltip title='Delete'>
                    <IconButton onClick={toggleDeleteModal} className='flex' aria-label="delete icon" component="label">
                     <DeleteOutlinedIcon/>
                    </IconButton>
                    </Tooltip>
                    <Tooltip title='Edit'>
                    <IconButton onClick={()=>toggleEdit()} className='flex' aria-label="edit icon" component="label">
                      <EditIcon variant='outlined'/>
                    </IconButton>
                    </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>
                  <Link to='/app/todo/todos'>
                  <Tooltip title='Tasks'>
                    <IconButton className='flex space-x-1' aria-label="sheet" component="label">
                      <ContentCopyIcon variant='outlined'/>
                      <Typography>Tasks</Typography>
                    </IconButton>
                    </Tooltip>
                    </Link>
                  </TableCell>
                </TableRow>
                <DeleteDialog/>
              </>
      )
      }
  return (
    <>
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>SHEET</Typography>
             </TableCell>
            <TableCell>
             <Typography variant='subtitle2'>CREATED AT</Typography>
            </TableCell>
            <TableCell>
             <Typography variant='subtitle2'>ACTIONS</Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { sheets?.map((item,index)=>
             <SheetItem key={index} {...item}/>
       )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default SheetList