import React from 'react'
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import ResumeLayout from './ResumeLayout';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ThemeSelector from '../../main/components/ThemeSelector';
import LineHeight from '../../main/components/LineHeight';
function FormatBar() {
    const printPdf = ()=>{
        window.print();
      }
  return (
    <div className='fixed top-0 left-0 w-full h-12 bg-white flex py-2  justify-evenly items-center print:hidden shadow-2xl'>
    <Link to='/app/resumebuilder'>
     <Chip color='primary' className='hover:cursor-pointer' icon={<ArrowBackIcon />} label="Back to editor" variant="outlined" />
    </Link>
    <div className='space-x-2'>
     <ThemeSelector/>
     <LineHeight/>
    </div>
    <div className='flex space-x-2 items-center'>
       <ResumeLayout/>
        <Button variant='outlined'  onClick={()=> printPdf()} size='small' startIcon={<PrintIcon />}>
          Print
        </Button>
    </div>
    
    </div>
  )
}

export default FormatBar