import React,{useState} from 'react';
import { usePreview } from '../../context/PreviewContext';
import { useMain } from '../../../main/context/MainContext';
import { db } from '../../models/db';

function CustomAddModal() {
     const {theme} = useMain();
     const {toggleCustomModal} = usePreview();
     const [title, setTitle] = useState('');
     const [type, setType] = useState('TEXT');
     const visibility = true; 
     const addSection = async(e)=>{
      e.preventDefault();
       if(title==='')
       return;
      await db.custom.add({
        title,type,visibility,resumeId: parseInt(localStorage.getItem('resume'))
      });
      return toggleCustomModal();
     } 

  return (
    <div className="fixed z-30 flex justify-center items-center top-0 left-0 w-screen h-screen bg-black opacity-90 ml-0">
              <div className="h-[60%] w-[50%] bg-white rounded-2xl">
                <div className="flex justify-between items-center w-full border-b-2 border-slate-300 p-2">
                  <h1 style={{color: theme}} className={`text-color-${theme} text-xl p-2`}>Add more sections</h1>
                  <div onClick={()=>toggleCustomModal()} className="p-2 cursor-pointer">X</div>
                </div>
                <form onSubmit={(e)=>addSection(e)} className="p-8 m-4">
                  <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} p-2 mb-8`}>
                    <input onChange={(e)=>setTitle(e.target.value)} className="appearance-none bg-transparent border-none w-full text-gray-900 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Title (Skills,Certificates,etc)"/>
                   </div>
                   <div className="relative w-full">
                    <select style={{borderColor: theme}} onChange={(e)=>setType(e.target.value)} className={`block appearance-none w-full bg-white border-b border-color-${theme} text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-color-${theme}`}>
                      <option>TEXT</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                  <div className="p-2 w-full flex justify-center item-center mt-8 space-x-12">
                    <button style={{backgroundColor: theme}} type='submit' className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24 `}>Add</button>
                  </div>
                </form>
              </div>
          </div>
  )
}

export default CustomAddModal