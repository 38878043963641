import React,{useContext ,useState } from 'react';
import { db } from "../models/db";

const AlgorithmsContext = React.createContext();

export const useAlgorithms = ()=>{
    return useContext(AlgorithmsContext);
   }

   export const AlgorithmsProvider = ({children})=>{

    const [algorithmName, setAlgorithmName] = useState('')
    const [isAlgorithmVisible, setIsAlgorithmVisible] = useState(true);
    const [level, setLevel] = useState("Advanced");

    const algorithmVisibility = ()=>{
        return setIsAlgorithmVisible(!isAlgorithmVisible);
      }

    const fieldAlgorithmName = (value)=>{
        return setAlgorithmName(value);
    }

    const fieldLevel = (value)=>{
        return setLevel(value);
    }

    const submit = async(e)=>{
        e.preventDefault();
        if(algorithmName==='')
        return;
        await db.algorithms.add({
        algorithmName,level,resumeId: parseInt(localStorage.getItem('resume'))
       });

       return setAlgorithmName('');
    }

    const value = {
        fieldAlgorithmName,fieldLevel,algorithmName,submit,algorithmVisibility,isAlgorithmVisible
       }
  
        return(
          <AlgorithmsContext.Provider value={value}>
              {children}
          </AlgorithmsContext.Provider>
  
        )
  }
