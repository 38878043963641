import React from 'react'
import { useMargin } from '../../../context/MarginContext';
import { useMain } from '../../../../main/context/MainContext';
import { useTheme } from '../../../context/ThemeContext';
import { Slider } from '@mui/material';
import { db } from "../../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
function ContactLive() {
    const {changeContact,sectionMargin} = useTheme();
    const {theme} = useMain();
    const {marginEdit} = useMargin(); 
    const resume = useLiveQuery(() => db.resume.get(parseInt(localStorage.getItem('resume'))));
  return (
    <section className='w-full p-4 pl-0' style={{marginBottom: `${sectionMargin.contact}px`}}>
         <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>DETAILS</h1> 
         <div className="mb-4">
            <h1 className='text-xs font-semibold'>ADDRESS</h1>
              {(resume)?<div>{(resume?.address==='')?<div  className="">Address here</div>:<div dangerouslySetInnerHTML={{__html: resume?.address}} className="text-editor flex flex-col flex-wrap"></div>}</div>:<div  className="">Address here</div>}
            </div>
            <div className=" space-y-2">
              <div>
              <h1 className='text-xs font-semibold'>PHONE</h1>
                {(resume?.phone==='')?<h1 className="text-xs">Phone</h1>:<p className="text-xs">{resume?.phone}</p>}
              </div>
              <div>
               <h1 className='text-xs font-semibold'>EMAIL</h1>
                {(resume?.email==='')?<p className="text-xs">Email</p>:<p className="break-all text-xs">{resume?.email}</p>}
              </div>
            </div>
             {
              (marginEdit)?
                (<div className='w-full h-fit space-y-1 text-slate-700 text-xs  p-1 print:hidden'>
                 <p>Adjust bottom margin {`(${sectionMargin.contact} pixels)`}</p>
                  <Slider
                   onChange={(e)=>changeContact(e.target.value)}
                  value={sectionMargin.contact}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
              </div>):<></>
              }
            </section>
  )
}

export default ContactLive