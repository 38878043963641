import React from 'react';
import { useExperience } from '../../context/ExperienceContext';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useMain } from '../../../main/context/MainContext';


function ExperienceEditCard({count,exp,changeSort}) {
  const {theme} = useMain();
  const {
    toggleProjectsModal,toggleProjectsEditModal,deleteExperience,fetchProjectDetails,changeProjectId,fetchExperienceDetails,toggleExperienceEditModal
  } = useExperience();
  
  const projects = useLiveQuery(async () => {
    return await db.projects.toArray();
  }); 

  const setProjectId =async(id)=>{
    toggleProjectsModal()
    return await changeProjectId(id);
  }

  const setProjectEditId =async(id)=>{
    await fetchProjectDetails(id);
    toggleProjectsEditModal()
  }
 
  const setExperienceId =async(id)=>{
    await fetchExperienceDetails(id);
    toggleExperienceEditModal();
  }
 

  const deleteProjectItem = (id)=>{
    return db.projects.delete(id);
  }

  return (
    <>
          <div className="border border-t-1 w-full border-slate-200 p-2 my-2 bg-white">
            <div className="flex p-2 items-center w-full space-x-1 ">
              <div className='flex w-full justify-between'>
              <div className='flex flex-col'>
                <p style={{color: theme}} className={`text-color-${theme} text-sm`}>{exp.start.slice(0,-3)}<span className='px-3'>&harr;</span>{(exp.end==='Present')?exp.end:exp.end.slice(0,-3)}</p>
                <p className={`font-bold text-xs`}>{exp.company}</p>
                </div>
              <div className="flex flex-col px-3 pb-1 justify-end ">
               <p className='text-xs font-bold'>{exp.city+' '+exp.country}</p>
               <p className='text-xs font-bold'>{exp.designation}</p>
              </div>
              </div>
            </div>
            
            <div className="flex flex-col mx-4">
                              {
                                projects?.map((project,index)=>(
                                  (exp.id===project.expId)?
                                  (<div key={index} className="p-3 border border-slate-300 ">
                              <div className="flex justify-between">
                                <div className='text-xs'>{project.projectName}</div>
                                
                              </div>
                              <div className='p-2 m-1 text-editor editor-view' dangerouslySetInnerHTML={{__html: project.contributions}}></div>
                              <div className="flex justify-end space-x-2">
                                  <button style={{backgroundColor: theme}}
                                   onClick={()=>setProjectEditId(project.id)} 
                                   className={`text-xs  rounded-xl px-2 bg-color-${theme} hover:bg-slate-500 hover:text-white text-white`}>
                                     EDIT
                                    </button>
                                   <button onClick={()=>deleteProjectItem(project.id)}
                                    className="text-xs rounded-xl px-2 bg-slate-500 hover:bg-red-300 text-white">
                                    DELETE</button> 
                                </div>
                              </div>):<></>
                                ))
                            }
                          </div>
                          <div className='flex justify-end space-x-2 p-1 mr-6'>
             <button onClick={()=>setProjectId(exp.id)} 
              style={{borderColor: theme,color: theme}}
              className={`text-xs rounded-xl px-2 border `}>
               ADD PROJECTS
             </button>
             <button onClick={()=>setExperienceId(exp.id)} 
              style={{backgroundColor: theme}}
                className={`text-xs rounded-xl px-2 hover:bg-slate-500 text-white`}>
                EDIT
            </button> 
             <button onClick={()=>deleteExperience(exp.id)} 
              className="text-xs rounded-xl px-2 bg-slate-500 hover:bg-red-300 text-white">
               DELETE
               </button> 
            </div>
            </div>
            </>
  )
}

export default ExperienceEditCard