import React,{useState} from 'react'
import AppsIcon from '@mui/icons-material/Apps';
import { IconButton, Paper, Typography } from '@mui/material';
import ImportJson from './ImportJson';
import DownloadJson from './DownloadJson';
import { Link } from 'react-router-dom';
import Darkmode from './Darkmode';
import DarkMode from '../../main/components/DarkMode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
function NavBar() {
  // menu bar 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Paper className="fixed z-10  top-0 left-0 w-full mx-auto lg:px-36 flex  text-[#525252] min-w-screen  items-center justify-between py-4">
        <Link to='/app/todo/projects' className="text-xl ml-4 underline">HeiraTodo</Link>
        <div className='flex space-x-2 items-center'>
        {/* More menu button */}
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        fontSize='small'
        >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      {/* Apps */}
        <MenuItem onClick={handleClose}>
        <Link className="flex items-center" to='/'>
          <ListItemIcon>
            <AppsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Apps</ListItemText>
          </Link>
        </MenuItem>
        <ImportJson/>
        <DownloadJson/>
        <DarkMode/>
      </Menu>
        </div>
    </Paper>
  )
}

export default NavBar