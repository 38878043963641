import { useExperience } from '../../context/ExperienceContext';
import ExperienceEditCard from './ExperienceEditCard';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useState,useEffect,useRef } from "react";
import ReactTooltip from 'react-tooltip'
import { usePreview } from '../../context/PreviewContext';
import LiveModal from '../live/LiveModal';
import { useMain } from '../../../main/context/MainContext';

function Experience() {

  const {theme} = useMain();
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState("start");
  const {toggleExperienceModal,expRef,experienceVisibility,isExperienceVisible,company,country,city,start,end,designation}= useExperience();
  const toolRef = useRef();
  const {toggleLiveModal,liveModal} = usePreview();

  const changeSort = (type)=>{
    setSort(type);
  }

  const addButtonAction = ()=>{
    toggleExperienceModal();
    return toggleLiveModal();
  }
    
    const experience = useLiveQuery(() => 
       db.experience.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    ); 

    useEffect(() => {
        db.experience.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).count((count)=>{
          setCount(count);
        })
    
    }, [experience]);

    const deleteRecords = async ()=>{
        return  await db.experience.clear().then(()=>{
          db.projects.clear();
        });
    }

  return (
           <div className='m-4 mb-0 p-3 pb-1 shadow-2xl bg-white'>
             <div  
               className="flex justify-between items-center ">
             <div className='flex items-center space-x-1'>
            <h1 style={{color: theme}}>Experience</h1>
            </div>

                  {
                    (isExperienceVisible)?
                    <svg onClick={experienceVisibility} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 cursor-pointer" fill="none " viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>:
                    <svg onClick={experienceVisibility} xmlns="http://www.w3.org/2000/svg" className="text-teal-700 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  }
              </div>
              <div className='mx-4'>
              {(experience)?experience.map((items,index)=>(<ExperienceEditCard key={index} sort={changeSort} count={count} exp={items}/>)):<></>}
              <div className="flex justify-center bg-white px-2 space-x-2 mb-0">
                <button style={{backgroundColor: theme}} onClick={()=>addButtonAction()} className={`bg-color-${theme} text-white text-xs rounded-xl px-2 hover:bg-slate-500`}>ADD COMPANY</button>
                {(count>0)?<button onClick={()=>deleteRecords()} className="bg-slate-500 hover:bg-red-300 text-white text-xs rounded-xl px-2">DELETE</button>:<></>}
              </div>
              </div>
             </div>
  )
}

export default Experience