import { db } from "../models/db";
import React,{ useState } from "react";
import { useContact } from "../context/ContactContext";
import { useForm } from "../context/FormContext";
import { useTheme } from "../context/ThemeContext";
import TestData from "./TestData";
import { Link, useHistory } from 'react-router-dom';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { IconButton,  Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from '@mui/material';
import ThemeSelector from "../../main/components/ThemeSelector";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import Menu from '@mui/material/Menu';
import ResumeLayout from "./ResumeLayout";
const NavBar= ()=> {

  const {theme} =useTheme();
  const history = useHistory();
  const {fieldEmail,fieldPhone,fieldAddress} = useContact();
  const{fieldName,fieldDesc} =useForm();
 
  // menu bar 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //  Drop data base to rest resume data
      const dropDataBase=()=>{
        manageModal();
        const id = parseInt(localStorage.getItem('resume'));
        db.experience.where('resumeId').equals(id).delete();
        db.education.where('resumeId').equals(id).delete();
        db.libraries.where('resumeId').equals(id).delete();
        db.links.where('resumeId').equals(id).delete();
        db.algorithms.where('resumeId').equals(id).delete();
        db.projects.where('resumeId').equals(id).delete();
        db.highlights.where('resumeId').equals(id).delete();
        db.coding.where('resumeId').equals(id).delete();
        db.languages.where('resumeId').equals(id).delete();
        db.custom.where('resumeId').equals(id).delete();
        db.custom_items.where('resumeId').equals(id).delete();
        db.resume.update(id,{
          name: '',
          title: '',
          email: '',
          phone: '',
          address: ''
        })
      }

      // Reset dialog box
      const [isLinkModal, setIsLinkModal] = useState(false);
      const manageModal = ()=>{
        handleClose();
        return setIsLinkModal(!isLinkModal)
    }
    
      const LinkModal = ()=>{
        return <Dialog
                open={isLinkModal}
                onClose={()=>manageModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Reset entries?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure want to clear all entries?!<br/>
                      Can not undo .
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>manageModal()}>Cancel</Button>
                  <Button color='primary' onClick={()=>dropDataBase()} autoFocus>
                    Reset
                  </Button>
                </DialogActions>
              </Dialog>
      }

  return (
    <>
    <div className="fixed z-10 border-b border-slate-200 top-0 left-0 w-full flex bg-white text-[#525252] min-w-screen h-12 items-center justify-between shadow-sm">
        <Link to='/app/resume/home' className="text-xl ml-4 hover:cursor-pointer">Resume Builder</Link>
        <div className="flex items-center justify-center">
        {/* Resume Layout */}
        <ResumeLayout/>
        {/* Home resume */}
        <Tooltip title='Go to Home'>
        <Link to='/'>    
        <IconButton  className='flex flex-col justify-center' color="primary" aria-label="apps icon" component="label">
         <HomeIcon/>
        </IconButton>
        </Link> 
        </Tooltip>
        {/* Theme selector */}
        <ThemeSelector/>
        {/* More menu button */}
        <Tooltip title='More'>
        <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        fontSize='small'
        >
        <MoreVertIcon />
      </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      {/* Apps */}
        
        {/* Reset resume */}
        <MenuItem onClick={()=>manageModal()}>
          <ListItemIcon>
            <RestartAltOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reset</ListItemText>
        </MenuItem>
     {/* Demo data  */}
        <TestData />
      </Menu>
        </div>
    </div>
    <LinkModal/>
    </>
  )
}

export default NavBar;