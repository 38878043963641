
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useCustom } from "../../context/CustomContext";
import React,{useState,useEffect} from 'react';
import { useMain } from "../../../main/context/MainContext";

function CustomEditModal() {
    const {toggleCustomEditModal,content,changeContent,submitEdit} = useCustom();
    const {theme} = useMain();
  return (
    <div className="fixed z-10 top-0 h-screen left-0 border-2 border-slate-300 bg-white w-1/2">
        <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                Edit
        </div>
        <div className=" flex flex-col h-fit items-center">
            <div className="modal p-1 w-full max-w-lg h-[512px] space-y-2 overflow-y-scroll">
            <div className="flex flex-col">
            <EditorToolbar toolbarId={'EditModal'}/>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={changeContent}
              placeholder={"Add your content"}
              modules={modules('EditModal')}
              formats={formats}
            />
            </div>
            </div>
            </div>
            <div className="p-2 w-full flex justify-center item-center space-x-12">
                <button onClick={toggleCustomEditModal} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                <button style={{backgroundColor: theme}} onClick={()=>submitEdit()} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Save</button>
              </div>
    </div>
  )
}

export default CustomEditModal