import React from 'react'
import Algorithms from './Algorithms';
import Contact from './Contact';
import CustomSection from './CustomSection';
import Education from './Education';
import Experience from './Experience';
import Languages from './Languages';
import Libraries from './Libraries';
import Links from './Links';
import PersonalDetails from './PersonalDetails';

const Editor=() => {
  return (
          <div className="editor fixed top-10 left-0 mr-2 lg:w-1/2 w-full h-full overflow-y-scroll ">
          <PersonalDetails/>
          <Contact/>
          <Experience/>
          <Education/>
          <Links/>
          <Libraries/>
          <Languages/>
          <Algorithms/>
          <CustomSection/>
          </div>
        )
}

export default Editor;