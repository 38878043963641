import React,{useContext ,useState,useEffect } from 'react';
import { db } from "../models/db";

const ContactContext = React.createContext();

export const useContact = ()=>{
    return useContext(ContactContext);
   }

   export const ContactProvider = ({children})=>{

    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isContactVisible, setIsContactVisible] = useState(true);

    const [contactModal, setContactModal] = useState(false);

    useEffect(() => {
      db.resume.get(parseInt(localStorage.getItem('resume'))).then((item)=>{
        setAddress(item.address);
        setEmail(item.email);
        setPhone(item.phone);
      })
    });
    const contactVisibility = ()=>{
      return setIsContactVisible(!isContactVisible);
    }

    const fieldEmail =(value)=>{
      db.resume.update(parseInt(localStorage.getItem('resume')),{
        email: value
      })
          return setEmail(value);
        }

    const fieldPhone =(value)=>{
      db.resume.update(parseInt(localStorage.getItem('resume')),{
        phone: value
      })
        return setPhone(value);
      }
    
      const fieldAddress =(value)=>{
        db.resume.update(parseInt(localStorage.getItem('resume')),{
          address: value
        })
        return setAddress(value);
      }

    
    function toggleContactModal(){
      return setContactModal(!contactModal);
    }

    const saveContactDetails = ()=>{
      return toggleContactModal();
    }

    function closeContactModal(){
      return toggleContactModal()
    }

    const value = {
        phone,email,address,contactModal,
        fieldEmail,fieldPhone,fieldAddress,toggleContactModal,saveContactDetails,closeContactModal,contactVisibility,isContactVisible
       }
  
        return(
          <ContactContext.Provider value={value}>
              {children}
          </ContactContext.Provider>
  
        )
  }