import { useModal } from "../context/ModalContext";
import React,{useContext} from "react";
import { db } from "../model/db";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMain } from '../../main/context/MainContext';

function RootTaskView() {
  const {
    toggleRootTaskView,
    rootTaskView,
    editId,
    changeEditNote,
    note,
    title,
    brief,
    changeEditBrief,
    changeEditTitle
  } = useModal();
  const {dark} = useMain();

  const updateTask = async () => {
    if (title === "") return;
    await db.todos.update(editId, { title: title, note: note, brief: brief });

    toggleRootTaskView();
  };
  return (
    <>
      <Dialog open={rootTaskView} onClose={toggleRootTaskView}>
        <DialogTitle>Edit task</DialogTitle>
        <DialogContent className='overflow-y-auto space-y-1'>
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            tabIndex={1} 
            name='title'
            value={title} 
            onChange={(e)=>changeEditTitle(e.target.value)}
          />
          <TextField
           id="brief"
           label="Brief"
           multiline
           rows={3}
           variant="standard"
           tabIndex={2}
           name='note'  
           value={brief} 
           onChange={(e)=>changeEditBrief(e.target.value)}
           fullWidth
        />
        <div className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`}>
        <EditorToolbar toolbarId={'t1'} className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`} />
        <ReactQuill tabIndex={3}
          className={`${dark? 'dark-mode': 'light-mode'}`}
          theme="snow"
          value={note}
          onChange={changeEditNote}
          placeholder={"Details"}
          modules={modules('t1')}
          formats={formats}
         />
         </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleRootTaskView}>Cancel</Button>
          <Button variant='contained' onClick={()=>updateTask()}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RootTaskView;
