import {Avatar, Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { useResume } from '../context/ResumeContext';
import { db } from '../models/db';
import { ContactPage } from '@mui/icons-material';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useMain } from '../../main/context/MainContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLiveQuery } from "dexie-react-hooks";

function Home() {
    const history = useHistory();
    const {changeResume} = useResume();
    const {themeDark} = useMain();

    const resumes = useLiveQuery(() => {
      return db.resume.reverse().toArray();
    }); 

    const gotoResume= (id)=>{
      changeResume(id);
      return history.push('/app/resumebuilder');
    }
    
    const deleteDb = (id)=>{
      db.resume.where('id').anyOf(id).delete();
      db.experience.where('resumeId').anyOf(id).delete();
      db.education.where('resumeId').anyOf(id).delete();
      db.highlights.where('resumeId').anyOf(id).delete();
      db.projects.where('resumeId').anyOf(id).delete();
      db.libraries.where('resumeId').anyOf(id).delete();
      db.languages.where('resumeId').anyOf(id).delete();
      db.algorithms.where('resumeId').anyOf(id).delete();
      db.links.where('resumeId').anyOf(id).delete();
      db.custom.where('resumeId').anyOf(id).delete();
      db.custom_items.where('resumeId').anyOf(id).delete();
    }
    const addNew = (e)=>
    {
     db.resume.add({
        'name': '',
        'title': '',
        'address': '',
        'email': '',
        'phone': '',
        'created_at': new Date()
    }).then((id)=>{
        changeResume(id);
        history.push('/app/resumebuilder');
    })   
    }
  return (
    <ThemeProvider theme={themeDark}>
    <CssBaseline/>
    <div className='lg:m-4 h-screen p-4'>
     <Typography className='ml-8'  variant='h1' sx={{color: 'text.secondary'}}>
      Resume Builder
     </Typography>
     <Button className='ml-8' onClick={(e)=>{addNew(e)}} variant="outlined" size="large" endIcon={<ArrowForwardIcon />}>
      Add new
    </Button>
    <Box className='mt-8 grid grid-cols-1 lg:grid-cols-2 gap-2'>
    {
      (resumes)?resumes.map((resume)=>{
        return <Paper  key={resume.id} className='w-full'>
     <List>
      <ListItem
      secondaryAction={
                    <Tooltip title='Delete resume'>
                    <IconButton onClick={()=>{deleteDb(resume.id)}} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                    </Tooltip>
                  }
      >
        <ListItemAvatar onClick={()=>gotoResume(resume.id)} className='hover:cursor-pointer'>
            <Avatar>
                <ContactPage/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText
         className='hover:cursor-pointer'
         onClick={()=>gotoResume(resume.id)} 
         primary={(resume.name==='')?'Name':resume.name}
         secondary={(resume.title==='')?'Title':resume.title}
        />
      </ListItem>  
     </List>
    </Paper>
      }):<></>
    }
    
    </Box>
    </div>
    </ThemeProvider>
  )
}

export default Home