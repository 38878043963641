import React from 'react';
import { usePreview } from '../../context/PreviewContext';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";

import CustomList from './CustomList';
import { useMain } from '../../../main/context/MainContext';

function CustomSection() {
  const {theme} = useMain();
  const {toggleCustomModal} = usePreview();

  const custom = useLiveQuery(async () => {
    return await db.custom.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
  }); 

  return (
    <>
     {
      custom?custom.map((items,index)=>(<CustomList key={index} {...items}/>)):<></>
     }
    <div style={{color: theme}} onClick={toggleCustomModal} className={`flex space-x-1 text-sm  justify-center cursor-pointer items-center m-2 mx-4 mb-16 p-1 text-color-${theme} shadow-2xl bg-white`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
         <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
        <p>Add more sections</p>
    </div>
    </>
  )
}

export default CustomSection;