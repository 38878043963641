import React,{useRef} from 'react'
import './ResumePreview.css';
import { useExperience } from '../context/ExperienceContext';
import { useEducation } from '../context/EducationContext';
import { useLibraries } from '../context/LibrariesContext';
import { useLanguages } from '../context/LanguagesContext';
import { useAlgorithms } from '../context/AlgortithmsContext';
import { useLinks } from '../context/LinksContext';
import { useTheme } from "../context/ThemeContext";
import useDocumentTitle from '../../hooks/useDocumentTitle';
import AlgorithmsLive from '../components/live/AlgorithmsLive';
import ContactLive from '../components/live/ContactLive'
import EducationLive from '../components/live/EducationLive'
import ExperienceLive from '../components/live/ExperienceLive'
import LanguageLive from '../components/live/LanguageLive'
import LibraryLive from '../components/live/LibraryLive'
import LinksLive from '../components/live/LinksLive'
import PersonalDetailsLive from '../components/live/PersonalDetailsLive'
import CustomLive from '../components/live/CustomLive';
import PrintButton from '../components/live/PrintButton';
import {themeDark,themeLight} from '../../theme';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useMain } from '../../main/context/MainContext';
import ThemeSelector from '../../main/components/ThemeSelector';
import Resume1 from '../components/live/Resume1';
import Resume2 from '../components/live/Resume-2/Resume2';
import ResumeLayout from '../components/ResumeLayout';
import FormatBar from '../components/FormatBar';

function ResumePreview() {
    useDocumentTitle('Resume builder');
    const { themeLight,theme } = useMain();
    const {isExperienceVisible} = useExperience();
    const {isEducationVisible} =useEducation();
    const {isLibraryVisible} =useLibraries();
    const {isLanguageVisible} =useLanguages();
    const {isAlgorithmVisible} =useAlgorithms();
    const {isLinksVisible} = useLinks();
    const printRef = useRef();
    const {layout} = useMain();

    const printPdf = ()=>{
      window.print();
    }

  return (
    <ThemeProvider theme={themeLight}>
    <CssBaseline/>
    <div className="print-pdf w-screen h-full lg:p-64 p-0 lg:pt-24 ">
    <FormatBar/>
            <div ref={printRef} className="print:shadow-none flex p-4 min-h-screen bg-white shadow-2xl w-full">
              {
                layout==='resume1'?<Resume1/>:<Resume2/>
              }
           </div>
        </div>
        <PrintButton/>
        </ThemeProvider>
  )
}

export default ResumePreview;