import React from 'react'
import EducationList from './EducationList';
import { db } from '../../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useEducation } from '../../../context/EducationContext';
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from "../../../context/MarginContext";
import { useTheme } from '../../../context/ThemeContext';
function EducationLive() {
    const {sectionMargin,changeEducation} = useTheme();
    const {marginEdit} = useMargin();
    const {theme} = useMain();
    const education = useLiveQuery(async () => 
    await db.education.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    ); 
    const {institution,degree,country,city,score,start,end,highlightName,contributions} = useEducation();
   return (
    <section className="w-full p-8 pt-4 " style={{marginBottom: `${sectionMargin.education}px`}}>
    <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>EDUCATION</h1> 
    <TransitionGroup>
    {(education)?education.map((edu,index)=>(
        <CSSTransition key={index} timeout={500} classNames="item">
                    <EducationList key={index} {...edu}/>
                    </CSSTransition> 
                   )):<></>}
    </TransitionGroup>
</section>
  ) 
}

export default EducationLive