import { Box, Button, Drawer, Paper, Typography } from '@mui/material'
import React,{useState} from 'react'
import resume1 from '../images/resume1.jpg';
import resume2 from '../images/resume2.jpg';
import GridViewIcon from '@mui/icons-material/GridView';
import { useMain } from '../../main/context/MainContext';
function ResumeLayout() {
    const [state, setState] = useState(false);
    const selected = {borderColor: 'primary.main', borderWidth: '2px'};
    const nonSelected = {borderColor: 'grey.300', borderWidth: '1px'};
    const { layout,changeLayout } = useMain();
    const toggleDrawer = ()=>{
        return setState(!state);
    }
  return (
    <div>
        <Button variant='outlined'  onClick={()=> toggleDrawer()} size='small' startIcon={<GridViewIcon />}>
          Template
        </Button>
        <Drawer
        anchor='bottom'
        open={state}
        onClose={()=>toggleDrawer()}
        >
            <Paper  className='flex flex-col justify-center items-center w-full lg:p-8 space-x-8 '>
             <Typography variant='h6' className='uppercase' color='primary'>Select template</Typography>
             <div className='flex justify-center items-center w-full lg:p-8 p-2 lg:space-x-8 space-x-2'>
              <Box onClick={()=>{changeLayout('resume1')}} sx={layout === 'resume1'? selected: nonSelected } className='flex items-center justify-center lg:w-72 lg:h-96 w-48 h-64 border '>
                <img src={resume1} className='w-full h-full' alt='Resume #1'/>
              </Box>  
              <Box onClick={()=>{changeLayout('resume2')}} sx={layout=== 'resume2'? selected: nonSelected } className='flex items-center justify-center lg:w-72 lg:h-96 w-48 h-64 border'>
                <img src={resume2} className='w-full h-full' alt='Resume #2'/>
              </Box>
              </div>
            </Paper>
        </Drawer>
        </div>
  )
}

export default ResumeLayout