import React,{useEffect,useRef } from 'react'
import { useExperience} from '../../context/ExperienceContext'
import { useMain } from '../../../main/context/MainContext';

function ExperienceEdit() {
     const {theme} = useMain();
          const {
            toggleExperienceEditModal,
            fieldEditCompany,fieldEditCity,fieldEditCountry,
            fieldEditDesignation,fieldEditStartdate,fieldEditEnddate,
            fieldEditCurrent,inputEditEnd,editCompany,editCity,editCountry,editDesignation,editStart,editEnd,updateExperience
          } =useExperience();
            const checkRef = useRef();
          useEffect(() => {
           if(editEnd==="Present")
           {
            checkRef.current.checked=true;
            const attr = document.createAttribute("disabled");
            inputEditEnd.current.setAttributeNode(attr);
           }
          });
  return (
    <div className="fixed z-10 top-0 left-0  h-screen ml-2 mt-18 p-4 shadow-2xl bg-white w-1/2 cursor-pointer rounded-lg">
    <div className="relative flex flex-col justify-between h-screen">
        <div className="">
            <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                Experience edit
            </div>
            <div className="modal flex justify-center h-96 overflow-y-scroll item-center">
            <form className="w-full max-w-lg space-y-2">
                <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                  <input onChange={(e)=>{fieldEditCompany(e.target.value)}} value={editCompany} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Company name"/>
                </div>
                <div className="flex flex-row space-x-2">
                  <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                    <input onChange={(e)=>{fieldEditCity(e.target.value)}} value={editCity}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="City"/>
                   </div>
                   <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                    <input onChange={(e)=>{fieldEditCountry(e.target.value)}} value={editCountry}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Country"/>
                   </div>
                      <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2`}>
                        <input onChange={(e)=>{fieldEditDesignation(e.target.value)}} value={editDesignation}  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Designation"/>
                      </div>
                </div>
                <div className="w-2/3 p-3 ">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" for="grid-password">
                      Start
                    </label>
                    <input style={{borderColor: theme}} onChange={(e)=>{fieldEditStartdate(e.target.value)}} value={editStart}  className={`appearance-none w-full text-gray-700 border-b border-color-${theme} rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white`} type="date"/>
                  </div>
                  <div className="flex space-x-2">
                    <div className="w-2/3 p-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          End
                        </label>
                        <input style={{borderColor: theme}} ref={inputEditEnd} onChange={(e)=>{fieldEditEnddate(e.target.value)}} value={editEnd}  className={`appearance-none w-full text-gray-700 border-b border-color-${theme} rounded py-3 px-4 mb-3 leading-tight focus:outline-none bg-white`} type="date"/>
                        <p className='text-xs px-1 text-slate-700'>Only Year-Month will be displayed</p>
                      </div>
                      <div className="flex items-center w-1/3">
                        <input ref={checkRef} onChange={(e)=>fieldEditCurrent(e.target.checked)} className="leading-tight" type="checkbox"/>
                            <span className="ml-2">
                                Current
                            </span>
                      </div>
                  </div>
              </form>
              
              </div> 
            </div>
              <div className="mb-28 w-full flex justify-center item-center space-x-12">
                <button onClick={()=> toggleExperienceEditModal()} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                <button style={{backgroundColor: theme}} onClick={()=>updateExperience()} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Save</button>
              </div>
    </div>
    
    </div>
  )
}

export default ExperienceEdit;