import React from 'react'
import { createTheme } from '@mui/material/styles';
import { useModal } from '../context/ModalContext';
import { IconButton, Typography } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';
function Darkmode() {
  const {dark, toggleDark} = useModal();
  return (
    <IconButton onClick={()=>toggleDark()} className='flex flex-col justify-center' color="primary" aria-label="dark-mode icon" component="label">
    {
      (dark)?
        <>
        <ModeNightIcon variant='outlined'/>
        
        </>
      :<LightModeIcon variant='outlined'/>
    }
    </IconButton>
    )
}

export default Darkmode