import React from 'react'
import { useAlgorithms } from '../../context/AlgortithmsContext';
import { useContact } from '../../context/ContactContext';
import { useEducation } from '../../context/EducationContext';
import { useExperience } from '../../context/ExperienceContext';
import { useLanguages } from '../../context/LanguagesContext';
import { useLibraries } from '../../context/LibrariesContext';
import { useLinks } from '../../context/LinksContext';
import AlgorithmsLive from './AlgorithmsLive'
import EducationLive from './EducationLive'
import ExperienceLive from './ExperienceLive'
import LanguageLive from './LanguageLive'
import LibraryLive from './LibraryLive'
import LinksLive from './LinksLive'
import PersonalDetailsLive from './PersonalDetailsLive'
import CustomLive from './CustomLive';
import ContactLive from './ContactLive';
import { useMain } from '../../../main/context/MainContext';

function Resume1() {
  const {isExperienceVisible} = useExperience();
  const {isEducationVisible} =useEducation();
  const {isLibraryVisible} =useLibraries();
  const {isLanguageVisible} =useLanguages();
  const {isAlgorithmVisible} =useAlgorithms();
  const {isLinksVisible} = useLinks();
  const {lineHeight} = useMain();
  return (
    <>
         <div style={{lineHeight: `${lineHeight}px`}} className="w-4/6 print:w-4/6">
              <PersonalDetailsLive/>
               {(isExperienceVisible)?<ExperienceLive/>:<></>}
               {(isEducationVisible)?<EducationLive/>:<></>}
               {(isLinksVisible)?<LinksLive/>:<></>}
               <CustomLive/>
          </div>
          <div style={{lineHeight: `${lineHeight}px`}} className="min-h-screen w-2/6  print:w-2/6">
            <div className="bg-slate-200 print:bg-slate-200 w-full h-full p-2 text-xs">
            <ContactLive/>
            {(isLibraryVisible)?<LibraryLive/>:<></>}
            {(isLanguageVisible)?<LanguageLive/>:<></>}
            {(isAlgorithmVisible)?<AlgorithmsLive/>:<></>}
            </div>
         </div>
    </>
  )
}

export default Resume1