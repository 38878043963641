import React from 'react'
import EducationList from './EducationList';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useEducation } from '../../context/EducationContext';
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from "../../context/MarginContext";
import { useTheme } from '../../context/ThemeContext';
function EducationLive() {
    const {sectionMargin,changeEducation} = useTheme();
    const {marginEdit} = useMargin();
    const {theme} = useMain();
    const education = useLiveQuery(async () => 
    await db.education.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    ); 
    const {institution,degree,country,city,score,start,end,highlightName,contributions} = useEducation();
   return (
    <section className="p-2 m-2 text-sm" style={{marginBottom: `${sectionMargin.education}px`}}>
    <div style={{borderColor: theme}}
 className={`border-l-2 border-color-${theme} mb-2`}>
        <h1 className="bg-slate-300 w-fit p-0.5">EDUCATION</h1>
    </div>
    <TransitionGroup>
    {(education)?education.map((edu,index)=>(
        <CSSTransition key={index} timeout={500} classNames="item">
                    <EducationList key={index} {...edu}/>
                    </CSSTransition> 
                   )):<></>}
    </TransitionGroup>
</section>
  ) 
}

export default EducationLive