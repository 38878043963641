import React,{useEffect,useState} from 'react'
import { useForm } from '../../context/FormContext';
import { useMain } from '../../../main/context/MainContext';
import { useResume } from '../../context/ResumeContext';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";

function PersonalDetails() {
      const {togglePersonalModal,resumeId} = useForm();
      const {theme} = useMain();
      const resume = useLiveQuery(() => db.resume.get(parseInt(localStorage.getItem('resume'))));
  return (
          <div onClick={()=>togglePersonalModal()} className="m-4 p-4 shadow-2xl bg-slate-50 cursor-pointer hover:bg-slate-200">
            <h1 style={{color: theme}} className={`text-color-${theme}`}>Personal details</h1>
            <div className="py-2 text-xs space-y-1">
              {(resume)?<h1 className="">{(resume?.name==='')? "Name": resume?.name}</h1>:<h1 className="">Name</h1>}
              {(resume)?<h1 className="">{(resume?.title==='')?'Describe yourself': resume?.title}</h1>:<p className="">Describe yourself</p>}
             </div>
           </div>
  )
}
export default PersonalDetails;