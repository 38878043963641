import React from 'react'
import { db } from '../../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from '../../../context/MarginContext';
import MarginExperienceEdit from '../MarginExperienceEdit';

function ExperienceList({...exp}) {
  const {theme} = useMain();
  const {marginEdit} = useMargin();
  const projects = useLiveQuery(async () => {
    return await db.projects.toArray();
  }); 
  return (
    <div className='pb-2' style={{marginBottom: `${exp.margin}px`}}>
    <div className="flex justify-between items-center text-xs font-semibold">
        <p>{exp.designation}</p>
        <p>{exp.city},{exp.country}</p>
    </div>
    <div className="text-xs">
        <p className='font-semibold'>{exp.company}</p>
        <p >{exp.start.slice(0,-3)}<span className='text-xl px-2'>&harr;</span>{(exp.end==='Present')?exp.end:exp.end.slice(0,-3)}</p>
    </div>
    <TransitionGroup>
    {
      projects?.map((project,index)=>(
                                  (exp.id===project.expId)?
                                  (
                                    <CSSTransition key={index} timeout={500} classNames="item">
                                    <div className='my-2' key={index}>
                                    <p className='text-xs'>{project.projectName}</p>
                                      <div className='my-1 font-normal text-editor editor-view' dangerouslySetInnerHTML={{ __html: project.contributions}}></div>
                                    </div>
                                    </CSSTransition>
            ):<></>
                                ))
    }
    </TransitionGroup>
    {(marginEdit)?<MarginExperienceEdit id={exp.id} margin={exp.margin} />:<></>}
</div>
  )
}

export default ExperienceList