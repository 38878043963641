import React from 'react'
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db";
import { useMain } from '../../../main/context/MainContext';

function CustomLive() {

  const {theme} = useMain();
    const custom = useLiveQuery(async () => {
        return await db.custom.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
      }); 

      const customItems = useLiveQuery(async () => {
        return await db.custom_items.toArray();
      }); 

      const CustomItems = ({...customItem})=>{
        return <div className=" flex  items-center space-x-1 ">
                <div dangerouslySetInnerHTML={{__html : customItem.item}} className='text-editor '>
               </div>
        </div>
      }
  return (
        custom?custom.map((section,index)=>(
            (section.visibility)?
            <section key={index} className="p-2 m-2 text-sm  w-full">
              <div style={{borderColor: theme}}
               className={`border-l-2 border-color-${theme} mb-2`}>
                  <h1 className="bg-slate-300 w-fit p-0.5">{section.title.toUpperCase()}</h1>
                </div>
                <div className='p-4 '>
                {
              (customItems)?customItems.map((customItem,index)=>(
                (customItem.itemId===section.id)?<CustomItems key={index} {...customItem}/>:<></>
                )):<></>
             }
                </div>
          </section>:<></>)):<></>
  )
}

export default CustomLive