import { useEducation } from '../../context/EducationContext';
import EducationEditCard from './EducationEditCard';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect,useState } from 'react';
import { useMain } from '../../../main/context/MainContext';

function Education() {
    const {theme} = useMain();
    const {toggleEducationModal}= useEducation();
    const [count, setCount] = useState(0);
    const education = useLiveQuery(async () => 
       await db.education.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    ); 

    useEffect(() => {
      db.education.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).count((count)=>{
        setCount(count);
      })
  
  }, [education]);

    const {isEducationVisible,educationVisibility} = useEducation();

    const deleteRecords = async ()=>{
      return  await db.education.clear().then(()=>{
        db.highlights.clear();
      });
  }

  return (
           <div className=' m-4 mb-0 p-3 pb-1 shadow-2xl bg-white'>
             <div className="flex  justify-between items-center">
                <h1 style={{color: theme}}>Education</h1>
                {
                    (isEducationVisible)?
                    <svg onClick={educationVisibility} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>:
                    <svg onClick={educationVisibility} xmlns="http://www.w3.org/2000/svg" className="text-teal-700 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  }
              </div>
              <div className='mx-4  mt-0'>
              {(education)?education.map((exp,index)=>(<EducationEditCard key={index} {...exp}/>)):<></>}
              <div className="flex justify-center bg-white px-2 space-x-2">
                <button style={{backgroundColor: theme}} onClick={toggleEducationModal} className={`bg-color-${theme} text-xs text-white rounded-xl px-2 hover:bg-slate-500`}>ADD DEGREE</button>
                {(count>0)?<button onClick={()=>deleteRecords()} className="text-xs bg-slate-500 hover:bg-red-300 text-white rounded-xl px-2">DELETE</button>:<></>}
              </div>
              </div>
              
              
             </div>
  )
}

export default Education;