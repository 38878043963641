import React,{useEffect,useState} from 'react';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from '../../models/db';
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from '../../context/MarginContext';
import { Slider } from '@mui/material';
import { useTheme } from '../../context/ThemeContext';
function LibraryLive() {
  const {sectionMargin,changeLibrary} = useTheme();
  const {theme} = useMain();
  const {marginEdit} = useMargin();
  const [advancedCount, setAdvancedCount] = useState(0);
  const [beginnerCount, setBeginnerCount] = useState(0);
  const [intermediateCount, setIntermediateCount] = useState(0);
  
  const advanced = useLiveQuery(async () => {
    return await db.libraries.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray(); 
  }); 

  const intermediate = useLiveQuery(async () => {
    return await db.libraries.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  const beginner = useLiveQuery(async () => {
    return await db.libraries.where('level').equals('Beginner').toArray();
  }); 

  useEffect(() => {
    db.libraries.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setAdvancedCount(count);
    })
    
  }, [advanced]);

  useEffect(() => {
    db.libraries.where('level').equals('Intermediate').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setIntermediateCount(count);
    })
    
  }, [intermediate]);
  
  useEffect(() => {
    db.libraries.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setBeginnerCount(count);
    })
    
  }, [beginner]);

  const LibraryList = ({count,value,items})=>{
    
     return (value === count-1)?<div className='px-1 text-xs'>{items.libraryName}</div>:<div className='px-1 text-xs'>{items.libraryName+','}</div>;
  }
  return (
    <section className="p-2  w-full" style={{marginBottom: `${sectionMargin.library}px`}}>
              <div style={{borderColor: theme}}
 className={`border-l-2 border-color-${theme} mb-2`}>
                  <h1 className="bg-slate-300 w-fit p-0.5">LIBRARIES</h1>
                </div>
              <div className='flex-container py-2 '>
                { (advancedCount>0)?(
                    <div className="w-full  ">
                   <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Advanced</h1>
                   <div className='flex-container'>
                   {advanced?advanced.map((item,index)=>(<LibraryList key={index} count={advancedCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                <div className='flex-container py-2 '>
                { (intermediateCount>0)?(
                    <div className="w-full  ">
                   <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Intermediate</h1>
                   <div className='flex-container'>
                   {intermediate?intermediate.map((item,index)=>(<LibraryList key={index} count={intermediateCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                <div className='flex-container py-2 '>
                { (beginnerCount>0)?(
                    <div className="w-full  ">
                   <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Beginner</h1>
                   <div className='flex-container'>
                   {beginner?beginner.map((item,index)=>(<LibraryList key={index} count={beginnerCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                {
              (marginEdit)?
                (<div className='w-full h-fit space-y-1 text-slate-700 text-xs p-1 print:hidden'>
                 <p>Adjust bottom margin {`(${sectionMargin.library} pixels)`}</p>
                  <Slider
                   onChange={(e)=>changeLibrary(e.target.value)}
                  value={sectionMargin.library}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
              </div>):<></>
              }
    </section>
  )
}

export default LibraryLive;