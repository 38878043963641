import React, { useState } from 'react'
import SheetsAdd from '../components/SheetsAdd';
import SheetList from '../components/SheetList';
import { useModal } from '../context/ModalContext';
import Dexie from 'dexie';
import { useHistory } from "react-router-dom";
import TabProject from '../components/TabProject';
import ProjectDetails from '../components/ProjectDetails';
import NavBar from '../components/NavBar';
import { Typography } from '@mui/material';

function Sheets() {
    const {currentSheet} = useModal();
    const [DBexist, setDBexist] = useState(false);
    const navigate = useHistory();
    

  Dexie.exists("todo").then(function(exists) {
    setDBexist(exists)
    if(!exists || currentSheet === undefined)
    navigate.push('/app/todo');

}).catch(function (error) {
    console.error("Oops, an error occurred when trying to check database existance");
});

    

  return (
    <>
    <NavBar/>

    <div className='max-h-screen mx-8 lg:mx-36 my-24'>
    <Typography variant='h4'>
        Sheets
    </Typography>
     {(DBexist && currentSheet!== undefined)?
     <TabProject id={parseInt(currentSheet)}/>
     :<></>}

     <SheetsAdd value={parseInt(currentSheet)}/>

     {(DBexist && currentSheet!== undefined)?<SheetList value={parseInt(currentSheet)}/>:<></>}
      </div>
    </>
  )
}

export default Sheets
