import React from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useLinks } from '../../context/LinksContext';
import { useMain } from '../../../main/context/MainContext';
import { useTheme } from '../../context/ThemeContext';
function LinksForm() {
    const {sectionMargin,changeLinks} = useTheme();  
    const {linkAddress,changeLinkAddress,addLink,toggleLinkFormModal} = useLinks();
    const {theme} = useMain();

    const closeModal = ()=>{
        changeLinkAddress("");
        return toggleLinkFormModal();
    }

  return (

    <div className="modal-body">
            <div className="flex flex-col justify-center">
                <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                   Add Link
                </div>
                 <form className="w-full px-16 py-4 flex justify-center flex-col">
                 <div className='light-mode'>
                 <EditorToolbar className='light-mode' toolbarId={'t1'}/>
                 <ReactQuill
                   className='light-mode'
                   theme="snow"
                   value={linkAddress}
                   onChange={changeLinkAddress}
                   placeholder={"Link your websites"}
                   modules={modules('t1')}
                   formats={formats}
                 />
                 </div>
                  </form>
                </div>
                  <div className="mb-28 w-full flex justify-center item-center space-x-12">
                  <button onClick={closeModal} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                  <button style={{backgroundColor: theme}} onClick={addLink} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Add</button>
                  </div>
        </div>
  )
}

export default LinksForm