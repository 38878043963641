import React from 'react'
import { useMargin } from '../../context/MarginContext';
import { useMain } from '../../../main/context/MainContext';
import { useTheme } from '../../context/ThemeContext';
import { Slider } from '@mui/material';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
function ContactLive() {
    const {changeContact,sectionMargin} = useTheme();
    const {theme} = useMain();
    const {marginEdit} = useMargin(); 
    const resume = useLiveQuery(() => db.resume.get(parseInt(localStorage.getItem('resume'))));
  return (
    <section className='w-full' style={{marginBottom: `${sectionMargin.contact}px`}}>
         <div className="p-2">
              <svg style={{color: theme}} xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 text-color-${theme}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              
              {(resume)?<div>{(resume?.address==='')?<div  className="">Address here</div>:<div dangerouslySetInnerHTML={{__html: resume?.address}} className="text-editor flex flex-col flex-wrap"></div>}</div>:<div  className="">Address here</div>}
            </div>
            <div className="p-2 space-y-2">
              <div className="flex items-center space-x-1">
                <svg style={{color: theme}} xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 text-color-${theme}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                {(resume?.phone==='')?<h1 className="text-xs">Phone</h1>:<p className="text-xs">{resume?.phone}</p>}
              </div>
              <div className="flex items-center space-x-1">
                <svg style={{color: theme}} xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 text-color-${theme}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                {(resume?.email==='')?<p className="text-xs">Email</p>:<p className="break-all text-xs">{resume?.email}</p>}
              </div>
            </div>
             {
              (marginEdit)?
                (<div className='w-full h-fit space-y-1 text-slate-700 text-xs  p-1 print:hidden'>
                 <p>Adjust bottom margin {`(${sectionMargin.contact} pixels)`}</p>
                  <Slider
                   onChange={(e)=>changeContact(e.target.value)}
                  value={sectionMargin.contact}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
              </div>):<></>
              }
            </section>
  )
}

export default ContactLive