//Using Reacts context API to make globally available data for components //
//Please check https://reactjs.org/docs/context.html //
//https://reactjs.org/docs/hooks-reference.html#usecontext//
//This context is for personal details  part.// 

import React,{useContext ,useState,useEffect } from 'react';
import data from "./FormData.json";
import { db } from "../models/db";

const FormContext = React.createContext();

export const useForm = ()=>{
    return useContext(FormContext);
   }

   export const FormProvider = ({children})=>{
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [personalModal, setPerosnalModal] = useState(false);

    //States for form fields//
  
    useEffect(() => {
      db.resume.get(parseInt(localStorage.getItem('resume'))).then((item)=>{
        setName(item.name);
        setTitle(item.title);
      })
    });

    //States for modal window.//
    //Rendering a component <PersonalDetailsForm/> conditionally//

    //Methods to change state on event change//
    const fieldName =(nameValue)=>{
      db.resume.update(parseInt(localStorage.getItem('resume')),{
        name: nameValue
      })
        return setName(nameValue);
      }
    
      const fieldDesc =(descValue)=>{
        db.resume.update(parseInt(localStorage.getItem('resume')),{
          title: descValue
        })
        return setTitle(descValue);
      }

    
    function togglePersonalModal(){
      return setPerosnalModal(!personalModal);
    }

    const savePersonalDetails = ()=>{
      return togglePersonalModal();
    }

    
    function closePersonalModal(){
      return togglePersonalModal()
    }

    const value = {
      title,name,personalModal,
      fieldDesc,fieldName,togglePersonalModal,savePersonalDetails,closePersonalModal
     }

      return(
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>

      )
}
