import React,{useState} from 'react'
import { db } from "../model/db";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function SheetsAdd(props) {
    const [title, setTitle] = useState("");
    
    const addSheet = async(e)=>{
        e.preventDefault();
        if(title.trim()==="")
        return;
        
        let createdAt = new Date();
        await db.sheets.add({
            "title": title,
            "createdAt": createdAt,
            "projectsId": props.value
        })
    return setTitle('');
    }
  return (
    <Paper
      className='p-1 flex justify-center w-full my-4'
      component="form"
      onSubmit={(e)=>addSheet(e)}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Add sheets"
        inputProps={{ 'aria-label': 'Add sheets' }}
        onChange={(e)=>setTitle(e.target.value)} 
        value={title}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type='submit' color='primary'  aria-label="directions">
        <AddCircleIcon fontSize='large' />
      </IconButton>
    </Paper>
    )
}

export default SheetsAdd;