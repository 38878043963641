import React,{useRef,useState} from 'react'
import { db } from "../model/db";
import { importDB } from "dexie-export-import";
import { useHistory } from "react-router-dom";
import { IconButton, Typography } from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
function ImportJson() {
    const [dialog, setDialog] = useState(false);
    const [error,setError] = useState();
    const navigate = useHistory();

      function ImportDialog() {
        const hiddenFileInput = useRef(null);

        const importTodo = event => {
          hiddenFileInput.current.click();
        };
  
        const handleChange = async (event) => {
          const file = event.target.files[0];
          const reader = new FileReader();
          
          try {
              if (!file) throw new Error(`Only files can be dropped here`);
              
              await db.delete().then(()=>db.open()).then(()=> importDB(file));
              navigate.push('/app/todo/projects');
            } catch (err) {
              navigate.push('/app/todo/projects');
            }
            setDialog(!dialog)
        };
        return (
      <Dialog
        open={dialog}
        onClose={()=>setDialog(!dialog)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Import json file?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By importing existing data will be deleted!<br/>
             Export existing data to save as a json file <br/>
             <span>{error}</span>
          </DialogContentText>
        </DialogContent>
        <input
           type="file"
           accept='.json'
           ref={hiddenFileInput}
           onChange={handleChange}
           style={{display: 'none'}}/>
        <DialogActions>
          <Button onClick={()=>setDialog(!dialog)}>Cancel</Button>
          <Button onClick={importTodo} autoFocus>
            Import
          </Button>
        </DialogActions>
      </Dialog>
        )
      }

  return (
    <>
        <MenuItem onClick={()=>setDialog(!dialog)}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Import</ListItemText>
        </MenuItem>   
    <ImportDialog/>

    </>
  )
}

export default ImportJson