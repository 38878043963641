import React from 'react'
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../models/db";
import { useMain } from '../../../../main/context/MainContext';

function CustomLive() {

  const {theme} = useMain();
    const custom = useLiveQuery(async () => {
        return await db.custom.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
      }); 

      const customItems = useLiveQuery(async () => {
        return await db.custom_items.toArray();
      }); 

      const CustomItems = ({...customItem})=>{
        return <div className=" flex  items-center space-x-1 ">
                <div dangerouslySetInnerHTML={{__html : customItem.item}} className='text-editor '>
               </div>
        </div>
      }
  return (
        custom?custom.map((section,index)=>(
            (section.visibility)?
            <section key={index}  className="w-full p-8 pt-4 ">
              <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>{section.title.toUpperCase()}</h1> 
                <div className='p-4 '>
                {
              (customItems)?customItems.map((customItem,index)=>(
                (customItem.itemId===section.id)?<CustomItems key={index} {...customItem}/>:<></>
                )):<></>
             }
                </div>
          </section>:<></>)):<></>
  )
}

export default CustomLive