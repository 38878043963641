import React from 'react'
import { useContact } from '../../context/ContactContext';
import { useMain } from '../../../main/context/MainContext';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
function Contact() {
    const {theme} = useMain();
    const {email,phone,address,toggleContactModal} = useContact();
    const resume = useLiveQuery(() => db.resume.get(parseInt(localStorage.getItem('resume'))));

  return (
    <>
          <div onClick={toggleContactModal} className="mt-0 m-4 p-4 shadow-2xl bg-slate-50 cursor-pointer hover:bg-slate-200 rounde-xl">
          <h1  style={{color: theme}}>Contact details</h1>
            <div className="py-2 space-y-1 text-xs">
              {(resume?.email==='')?<h1>Email</h1>:<h1>{resume?.email}</h1>}
              {(resume?.phone==='')?<h1>Phone</h1>:<p>{resume?.phone}</p>}
              {(resume)?<div>{(resume?.address==='')?<div>Address</div>:<div dangerouslySetInnerHTML={{__html: resume?.address}} ></div>}</div>:<div  className="">Address</div>}
             </div>
           </div> 
           </>  
  )
}

export default Contact;