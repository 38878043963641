import React from 'react';
import { db } from "../model/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useModal } from '../context/ModalContext';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import  ChildTaskModal  from "./ChildTaskModal";
import  SubTaskModal  from "./SubTaskModal";
import  TaskView  from "./TaskView";
import  RootTaskView  from "./RootTaskView";
import  TodoItem  from "./TodoItem";

function ToDoList(props) {
  const{ completed } = useModal();
  
  const todos =  useLiveQuery(
        () => db.todos.where('sheetId').equals(props.value).and((item)=>{
          if (completed)
            return item.isCompleted ===false && item.primaryRoot === true;

            return item.primaryRoot === true;
        }).sortBy('orderId'),[completed]
      );
      
    const swapItems = async(param)=>{
      try {
        let sourceId = todos[param.source.index].id;
        let destinationId = todos[param.destination.index].id;
        let sourceOrderId = todos[param.source.index].orderId;
        let destinationOrderId = todos[param.destination.index].orderId;
        return await db.todos.update(destinationId,{orderId: sourceOrderId}).then(async ()=>{
          return await db.todos.update(sourceId,{orderId: destinationOrderId})
        })
      } catch (error) {
        console.error(error);
      }
        
    }
    
  return (
    <>
      <DragDropContext onDragEnd={(param)=> swapItems(param)}>
      <Droppable droppableId="droppable-1" >
       { (provided,snapshot)=>
       <div className='h-full px-2 py-4' ref={provided.innerRef}  {...provided.droppableProps}>
       {todos?.map((item,index)=>
             <TodoItem key={index} item={item} index={index}/> 
       )}
       {provided.placeholder}
       </div>
       }
       </Droppable>
       </DragDropContext>
       <SubTaskModal/>
       <RootTaskView/>
       <ChildTaskModal/>
       <TaskView/>
    </>
  )
}

export default ToDoList