import React from 'react';
import NavBar from '../components/NavBar';
import { useExperience } from '../context/ExperienceContext'
import { useForm } from '../context/FormContext'
import Editor from '../components/editor/Editor'
import ExperienceEdit from '../components/editor/ExperienceEdit'
import ExperienceForm from '../components/editor/ExperienceForm'
import PersonalDetailsForm from '../components/editor/PersonalDetailsForm'
import ProjectsForm from '../components/editor/ProjectsForm'
import ProjectsEditForm from '../components/editor/ProjectsEditForm'
import Resume from '../components/live/Resume'
import { useEducation } from '../context/EducationContext'
import EducationForm from '../components/editor/EducationForm'
import EducationEdit from '../components/editor/EducationEdit'
import HighlightsForm from '../components/editor/HighlightsForm'
import HighlightsEditForm from '../components/editor/HighlightsEditForm'
import { useContact } from '../context/ContactContext'
import ContactForm from '../components/editor/ContactForm'
import { usePreview } from '../context/PreviewContext';
import { useLinks } from '../context/LinksContext';
import LinkEdit from '../components/editor/LinkEdit';
import CustomAddModal from '../components/editor/CustomAddModal';
import LinksForm from '../components/editor/LinksForm';
import { useCustom } from '../context/CustomContext';
import CustomEditModal from '../components/editor/CustomEditModal';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useMain } from '../../main/context/MainContext';
import InfoBar from '../components/editor/InfoBar';

function Layout() {
  useDocumentTitle('Resume builder');
  const {personalModal} = useForm();
  const {contactModal} = useContact();
  const {EducationModal,EducationEditModal,highlightsModal,highlightsEditModal} = useEducation(); 
  const {experienceModal,experienceEditModal,projectsModal,projectsEditModal} = useExperience();
  const {customModal} = usePreview();
  const {linkModal,linkFormModal} = useLinks();
  const {customEditModal} = useCustom();
  const {themeLight} = useMain();
  return (
    <ThemeProvider theme={themeLight}>
    <CssBaseline/>
    <NavBar/>
        <Editor/> 
        <Resume/>
        <InfoBar/>
        {(personalModal)?<PersonalDetailsForm/>:<></>}
        {(contactModal)?<ContactForm/>:<></>}
        {(experienceModal)?<ExperienceForm/>:<></>}
        {(projectsModal)?<ProjectsForm/>:<></>}
        {(experienceEditModal)?<ExperienceEdit/>:<></>}
        {(projectsEditModal)?<ProjectsEditForm/>:<></>}
        {(EducationModal)?<EducationForm/>:<></>}
        {(EducationEditModal)?<EducationEdit/>:<></>}
        {(highlightsModal)?<HighlightsForm/>:<></>}
        {(highlightsEditModal)?<HighlightsEditForm/>:<></>}
        {(linkModal)?<LinkEdit/>:<></>}
        {(linkFormModal)?<LinksForm/>:<></>}
        {(customModal)?<CustomAddModal/>:<></>}
        {(customEditModal)?<CustomEditModal/>:<></>}
        </ThemeProvider>
  )
}

export default Layout