import React,{useState} from 'react';
import { db } from '../model/db';
import { useModal } from '../context/ModalContext';
import SubTaskList from './SubTaskList';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Checkbox, IconButton, Tooltip, Typography, Paper, Box } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const TodoItem = ({item,index})=>{
    const{
        toggleSubTaskModal,changeChildTask,changeEditId,toggleRootTaskView,
      } = useModal();

  const [deleteModal, setDeleteModal] = useState(false);
  
  const toggleDeleteModal = ()=>{
    return setDeleteModal(!deleteModal);
  }

  
  const [children, setChildren] = useState([]);
    const [more, setMore] = useState(true);
    const [details, setDetails] = useState(false);
    
      
    const changeDetails = ()=>{
      return setDetails(!details);
    }

    const updateTask = async(id,e)=>{
      return await db.todos.update(id,{isCompleted: !!e.target.checked})
    }
    
    const viewTask = async(id)=>{
      const data = await db.todos.get(id);
      changeEditId(id,data);
      toggleRootTaskView();
    }

  async function subTodo (tasks){
    tasks.map(async function (task){
      children.push(task);  
      let childTask = await db.todos.get(task);
      if(childTask.children.length>0)
         { subTodo(childTask.children)}
        return;
})
   }

   async function deleteRoot(id){
     await db.todos.delete(id);
   }

   const deleteChildren = ()=>{
     children.map(async(item)=>{
        await db.todos.delete(item)
     })
   }

    const deleteTask =  (tasks,id)=>{
       toggleDeleteModal();
       tasks.map(async (task)=>{
        children.push(task);
          let childTask = await db.todos.get(task);
          if(childTask.children.length>0)
            subTodo(childTask.children);
            return;
       })
       deleteChildren();
       deleteRoot(id);
       setChildren([]);
    }
    

    const addChildren = (root)=>{
      changeChildTask(root);
      toggleSubTaskModal();
    }

    const DeleteDialog = ()=>{
      return (
        <Dialog
        open={deleteModal}
        onClose={toggleDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete task?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Deleting task will also delete all it's sub tasks!<br/>
              Can not undo .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteModal}>Cancel</Button>
          <Button color='error' onClick={()=>deleteTask(item.children,item.id)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      )
    }
  return(
    <Draggable key={item.id} draggableId={`draggable-${item.id}`} index={index} >
      {
        (provided)=>(
          <Box key={index} ref={provided.innerRef}  {...provided.draggableProps}>
          <Box className='mb-2'>
            <Paper variant='outlined' className='w-full  '>
              <Box className='flex flex-col  md:flex-row lg:flex-row md:justify-between lg:justify-between'>
               <Box className='flex items-center px-4 py-4'>
                 <Box className='flex lg:items-center space-x-2 w-full'>
                 <IconButton  {...provided.dragHandleProps} className='flex flex-col justify-center' color='primary' aria-label='apps icon' component='label'>
                  <DragHandleIcon variant='outlined'/>
                 </IconButton>
                 <Checkbox checked={item.isCompleted} onChange={(e)=>updateTask(item.id,e)} size='small' />
                 <Box onClick={()=> changeDetails()} className='w-full cursor-pointer'>
                    <Typography className={`${item.isCompleted && 'line-through'}`}>
                    {item.title}
                    </Typography>
                    <Typography variant='subtitle2' className={`${item.isCompleted && 'line-through'} ${!details && 'truncate w-48'} ${details && 'line-wrap w-full'}`}>
                      {item.brief}
                    </Typography>
                 </Box>
                 </Box> 
               </Box> 
               <Box className='px-4 py-4'>
                <Box className='flex md:justify-end lg:justify-end w-full space-x-1'>
                <Tooltip title='Edit task'>
                    <IconButton onClick={()=> viewTask(item.id)}  aria-label="edit task" component="label">
                      <ModeEditOutlineOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete task'>
                    <IconButton onClick={toggleDeleteModal}  aria-label="delete task" component="label">
                      <DeleteOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Add sub task'>
                 <Button onClick={()=> addChildren(item.id)} size="small">ADD SUBTASK</Button>
                </Tooltip>
                    <IconButton aria-label="Show child tasks" component="label">
                    {
                     (item.children.length>0)?
                      ((more)?
                        <ExpandMoreIcon onClick={()=> setMore(!more)} />:
                        <ArrowForwardIosIcon onClick={()=> setMore(!more)} />):
                      <div className='w-5 h-5'></div>
                     }
                    </IconButton>
                </Box>
               </Box>
              </Box>
              {
                (details)?
                <Paper variant='outlined' className='p-10 bg-slate-100 '>
                  <Box className='note'  dangerouslySetInnerHTML={{__html: item.note}}></Box> 
                </Paper>:
                <></>
              }
            </Paper> 
            <Box className={`${!more ? 'hidden' : ''}`}>
            {
              item.children?.map((childId,index)=>
              {
                return <SubTaskList key={childId} childId={childId} />
              })
            }
            </Box>
          </Box>
            <DeleteDialog/>
          </Box>
        )
      }
    </Draggable>
  )
}
export default TodoItem;