import React from 'react'
import { db } from '../../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import ExperienceList from './ExperienceList';
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from '../../../context/MarginContext';
import { PencilFill } from 'react-bootstrap-icons';
import { useTheme } from '../../../context/ThemeContext';

function ExperienceLive() 
{
    const {sectionMargin,changeExperience} = useTheme();
    const {theme} = useMain();
    const {marginEdit,toggleMarginEdit} = useMargin();
    const experience = useLiveQuery(() => 
    db.experience.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).reverse().sortBy('start'),[]
    );  

      return (
    <section className="w-full p-8 pt-4 text-sm "  style={{marginBottom: `${sectionMargin.experience}px`}}>
    <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>EXPERIENCE</h1> 
    <TransitionGroup>
    {
        (experience)?experience.map((exp,index)=>(
        <CSSTransition key={index} timeout={500} classNames="item">
            <ExperienceList key={index} {...exp}/>
        </CSSTransition>)):<></>
    }
    </TransitionGroup>
</section>
  )
}
export default ExperienceLive;