import React,{useState} from 'react'
import TodoList from '../components/TodoList';
import { useModal } from '../context/ModalContext';
import Dexie from 'dexie';
import TaskModal from '../components/TaskModal';
import { useHistory } from "react-router-dom";
import TabProject from '../components/TabProject';
import TabSheet from '../components/TabSheet';
import { db } from "../model/db";
import { useLiveQuery } from "dexie-react-hooks";
import NavBar from '../components/NavBar';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
function Todo() {
  const [DBexist, setDBexist] = useState(false);
  const {
    currentSheet,
    currentTodo,
    toggleTaskModal,
    changeCompleted,completed,addTop,changeAddTop
   } = useModal();

   const todos =  useLiveQuery(
    () => db.todos.where('sheetId').equals(parseInt(currentTodo)).and((item)=>{
      if (completed)
        return item.isCompleted ===false && item.primaryRoot === true;

        return item.primaryRoot === true;
    }).sortBy('orderId'),[completed]
  );

  const navigate = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  
  const toggleDeleteModal = ()=>{
    return setDeleteModal(!deleteModal);
  }

  Dexie.exists("todo").then(function(exists) {
    setDBexist(exists)
    if(!exists || currentSheet === undefined || currentTodo === undefined)
    navigate.push('/');

}).catch(function (error) {
    console.error("Oops, an error occurred when trying to check database existance");
});

const deleteAll = (sheetId)=>{
  toggleDeleteModal();
  db.todos.where('sheetId').anyOf(sheetId).delete();
}

    const DeleteDialog = ()=>{
      return (<></>
      )
    }
          
          
  return (
    <>
    <NavBar/>
    <div className='min-h-screen mx-8 lg:mx-36 my-24'>
    {(DBexist && currentSheet!== undefined && currentTodo!== undefined )?
        <div className='flex w-full space-x-1'>
        <TabProject id={currentSheet}/>
        <Typography>
         &#9674;
        </Typography>
        <TabSheet id={currentTodo}/>
        </div>
        :<></>}
    <Paper className='p-2 px-4 flex justify-between w-full my-4'>
      <Box className='flex space-x-2'>
       <FormControlLabel
        control={<Checkbox checked={completed} onChange={(e)=> changeCompleted(e.target.checked) }/>}
        label="Hide completed tasks" />
       <FormControlLabel 
        control={<Checkbox checked={addTop} onChange={(e)=> changeAddTop(e.target.checked) }/>}
        label="Add to top" />
      </Box>
      <Button  onClick={toggleTaskModal} variant='contained' size='small'>
       ADD TASK
      </Button>
    </Paper>
    <Box className='w-full min-h-screen'>
    {
     (DBexist && currentSheet!== undefined && currentTodo!== undefined)?
        <TodoList value={parseInt(currentTodo)}/>:<></>
    }
    </Box>
    <TaskModal value={parseInt(currentTodo)}/>
    </div>
    </>
  )
}

export default Todo;
