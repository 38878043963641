import React,{useContext ,useState,useEffect } from 'react';
import { createTheme } from '@mui/material/styles';

const MainContext = React.createContext();

export const useMain = ()=>{
    return useContext(MainContext);
   }

   export const MainProvider = ({children})=>{
    
    const [theme, setTheme] = useState('#0d9488');
    const [dark, setDark] = useState(true);
    const [mode, setMode] = useState('light');
    const [layout,setLayout] = useState('resume1');
    const [status,setStatus] = useState(true);
    const [lineHeight, setLineHeight] = useState(24);

    const colors = ['#0d9488','#6d28d9','#b12025','#22405c',
                    '#8B9A46','#0C7B93','#4F200D','#9C2C77',
                    '#048BC2','#238F51','#438797','#587b69',]

    const changeTheme = (value)=>{
        localStorage.setItem('theme', value);
        return setTheme(localStorage.getItem('theme'))
      }

      const changeLineHeight = (value)=>{
        return setLineHeight(value)
      }  
      const changeDark = ()=>{
        return setDark(!dark)
      }  
    
      const changeStatus = ()=>{
        return setStatus(!status);
      }
    
      const changeLayout =(value)=>{
        return setLayout(value);
      }
        
 // Dark theme config
    const themeDark = createTheme({
        palette: {
          mode: mode,
          primary: {
            main: '#0d9488',
          },
          secondary: {
            main: '#f50057',
          },
        }
        });
// Light theme config
    const themeLight = createTheme({
            palette: {
              mode: 'light',
              primary: {
                main: theme,
              },
              secondary: {
                main: '#f50057',
              },
            }
            });     

    useEffect(() => {
        if (localStorage.getItem('theme') === null) {
          localStorage.setItem('theme', '#0d9488' );
          return setTheme(localStorage.getItem('theme'))
          }

          if(dark){
            setMode('dark')
          }
          else{
            setMode('light')
          }

          return setTheme(localStorage.getItem('theme'));
      },[theme,dark]);

      const value = {theme, themeDark, themeLight, changeTheme,colors,dark,changeDark,changeStatus,status,changeLayout,layout,changeLineHeight,lineHeight}
  
        return(
          <MainContext.Provider value={value}>
              {children}
          </MainContext.Provider>
        )
   }
