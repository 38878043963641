import React,{useState,useEffect} from 'react'
import { db } from '../../models/db';
import { Slider } from '@mui/material';

function MarginExperienceEdit({id, margin}) {
    const [marginState, setMarginState] = useState(margin);
   useEffect(() => {
    setMarginState(margin);
   },[margin]);

    const updateMargin = (value)=>{
        db.experience.update(id,{'margin': value });
        return setMarginState(value);
    }
    
  return (
    <div className={`w-full h-fit px-2 space-y-1 text-xs text-slate-700 border border-slate-200 print:hidden`}>
     <Slider
        onChange={(e)=>updateMargin(e.target.value)}
        value={marginState}
        min={0}
        max={500}
        valueLabelDisplay="auto"
      />
      <p className='px-1'>Adjust bottom margin {`(${marginState} pixels)`}</p>
    </div>
  )
}

export default MarginExperienceEdit;