import { Slider } from '@mui/material';
import React from 'react'
import { useForm } from '../../context/FormContext';
import { useMain } from '../../../main/context/MainContext';
import { useTheme } from '../../context/ThemeContext';
function PersonalDetailsForm() {
  const {changePersonal,sectionMargin} = useTheme();
  const {theme} = useMain();
  const {savePersonalDetails,closePersonalModal,fieldName,fieldDesc,name,title, } = useForm(); 
  return (
    <div className="fixed z-10 top-0 left-0 min-h-screen ml-2 mt-18 p-4 shadow-2xl bg-white w-full lg:w-1/2 rounded-lg">
        <div className="flex flex-col h-screen justify-between">
            <div className="">
                <div style={{color: theme}} className={`"m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                    Personal details
                </div>
                 <form className="w-full m-4 p-4 max-w-lg flex flex-col space-y-6">
                    <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2 w-full`}>
                      <input onChange={(e)=>fieldName(e.target.value)} value={name} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Full Name"/>
                    </div>
                    <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2 w-full`}>
                        <input onChange={(e)=>fieldDesc(e.target.value)} value={title} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Describe yourself"/>
                      </div>
                      <div className={`w-full h-fit px-2 space-y-1 text-xs text-slate-700`}>
                        <p>Adjust bottom margin {`(${sectionMargin.personalDetails} pixels)`}</p>
                        <Slider
                          onChange={(e)=>changePersonal(e.target.value)}
                          value={sectionMargin.personalDetails}
                          min={0}
                          max={500}
                          valueLabelDisplay="auto"
                        />
                     </div>
                  </form>
                </div>
                  <div className="mb-28 w-full flex justify-center item-center space-x-12">
                    <button onClick={()=>closePersonalModal()} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                    <button style={{backgroundColor: theme}} onClick={()=>savePersonalDetails()} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Save</button>
                  </div>
        </div>
        </div>
  )
}

export default PersonalDetailsForm;