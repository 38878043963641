import React,{useContext ,useState } from 'react';
import { db } from '../model/db';

const ModalContext = React.createContext();

export const useModal = ()=>{
    return useContext(ModalContext);
   }

   export const ModalProvider = ({children})=>{ 
    const [taskModal, setTaskModal] = useState(false);
    const [taskCompletedModal, setTaskCompletedModal] = useState(false);
    const [subTaskModal, setSubTaskModal] = useState(false);
    const [rootTaskView, setRootTaskView] = useState(false);
    const [taskView, setTaskView] = useState(false);
    const [childTaskModal, setChildTaskModal] = useState(false);
    const [root, setRoot] = useState();
    const [mapChildren, setMapChildren] = useState();
    const [childRoot, setChildRoot] = useState();
    const [rootChildren, setRootChildren] = useState();
    const [editId,setEditId] = useState();
    const [edit,setEdit] = useState();
    const [title, setTitle] = useState();
    const [note, setNote] = useState();
    const [brief,setBrief] = useState('');
    const [completed, setCompleted] = useState(false);
    const [dark,setDark] = useState(true);
    const [editorClass,setEditorClass] = useState();
    const [currentSheet,setCurrentSheet] = useState(localStorage.currentSheet);
    const [currentTodo,setCurrentTodo] = useState(localStorage.currentTodo);
    const [addTop,setAddTop] = useState(false);

    const changeCurrentTodo = (value)=>{
      localStorage.setItem("currentTodo",value)
      return setCurrentTodo(localStorage.getItem("currentTodo"))
    }

    const changeCurrentSheet = (value)=>{
      localStorage.setItem("currentSheet",value)
      return setCurrentSheet(localStorage.getItem("currentSheet"))
    }

    const changeEditorClass = (value)=>{
      return setEditorClass(value)
    }

    const toggleDark = ()=>{
      return setDark(!dark)
    }
      const changeEditTitle = (value)=>{
        return setTitle(value);
      }
      
      const changeEditNote = (value)=>{
        return setNote(value)
      }

      const changeEditBrief = (value)=>{
        return setBrief(value)
      }
  
    const toggleTaskModal = ()=>{
        return setTaskModal(!taskModal);
      }

      const toggleSubTaskModal = ()=>{
        return setSubTaskModal(!subTaskModal);
      }
  
      const toggleChildTaskModal = ()=>{
        return setChildTaskModal(!childTaskModal);
      }

      const toggleRootTaskView = ()=>{
        return setRootTaskView(!rootTaskView);
      }

      const toggleTaskView = ()=>{
        return setTaskView(!taskView);
      }

      const toggleTaskCompletedModal = ()=>{
        return setTaskCompletedModal(!taskCompletedModal);
      }

      const changeChildTask = (root)=>{
        setRoot(root);

        db.todos.get(root).then((map)=>{
          setMapChildren(map.children)
        })
      }

      const changeEditId = async (id,data)=>{
        setEditId(id);
        changeEditTitle(data.title);
        changeEditNote(data.note);
        changeEditBrief(data.brief);  
      }

      const changeSubChildTask = (root)=>{
        setRoot(root);

        db.todos.get(root).then((map)=>{
          setMapChildren(map.children)
        })
      }
      
      const changeCompleted = (status)=>{
        return setCompleted(!!status)
      }

      const changeAddTop = (status)=>{
        return setAddTop(!!status)
      }

      const value = {
        taskModal,subTaskModal,childTaskModal,taskView,rootTaskView,taskCompletedModal,root,childRoot,
        toggleTaskModal,toggleSubTaskModal,toggleChildTaskModal,toggleTaskCompletedModal,
        toggleRootTaskView,toggleTaskView,changeChildTask,changeSubChildTask,changeEditId,rootChildren,mapChildren,editId,edit,completed,changeCompleted,
        changeEditNote,changeEditTitle,note,title,dark,toggleDark,changeEditBrief,brief,changeEditorClass,editorClass,
        changeCurrentSheet,currentSheet,changeCurrentTodo,currentTodo,changeAddTop,addTop
       }
  
        return(
          <ModalContext.Provider value={value}>
              {children}
          </ModalContext.Provider>
  
        )
  }
