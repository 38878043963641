import React from 'react'
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from '../../context/MarginContext';
import MarginExperienceEdit from './MarginExperienceEdit';

function ExperienceList({...exp}) {
  const {theme} = useMain();
  const {marginEdit} = useMargin();
  const projects = useLiveQuery(async () => {
    return await db.projects.toArray();
  }); 
  return (
    <div className="text-sm" style={{marginBottom: `${exp.margin}px`}}>
    <div className="flex justify-between items-center text-xs font-semibold">
        <p style={{color: theme}} className={`text-color-${theme}`}>{exp.start.slice(0,-3)}<span className='text-xl px-2'>&harr;</span>{(exp.end==='Present')?exp.end:exp.end.slice(0,-3)}</p>
        <div className='flex space-x-1 items-center'>
          <svg style={{color: theme}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`h-3 w-3 text-color-${theme}`}>
          <path fillRule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clipRule="evenodd" />
          </svg>    
           <p>{exp.city},{exp.country}</p>
        </div>
    </div>
    <div className="flex justify-between text-xs font-semibold">
        <p>{exp.company}</p>
        <p>{exp.designation}</p>
    </div>
    <TransitionGroup>
    {
      projects?.map((project,index)=>(
                                  (exp.id===project.expId)?
                                  (
                                    <CSSTransition key={index} timeout={500} classNames="item">
                                    <div className='my-2' key={index}>
                                    <p className='text-xs'>{project.projectName}</p>
                                      <div className='my-1 font-normal text-editor editor-view' dangerouslySetInnerHTML={{ __html: project.contributions}}></div>
                                    </div>
                                    </CSSTransition>
            ):<></>
                                ))
    }
    </TransitionGroup>
    {(marginEdit)?<MarginExperienceEdit id={exp.id} margin={exp.margin} />:<></>}
</div>
  )
}

export default ExperienceList