import React from 'react'
import NightsStayIcon from '@mui/icons-material/NightsStay';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useMain } from '../context/MainContext';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

function DarkMode() {
    const {dark,changeDark}= useMain();
  return (
        <MenuItem onClick={()=>changeDark()}>
        {
          (dark)?<><ListItemIcon>
          <Brightness4Icon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Light</ListItemText>
        </>:
        <><ListItemIcon>
          <NightsStayIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Night</ListItemText>
        </>
        }
      </MenuItem>  
  )
}

export default DarkMode