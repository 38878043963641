import Dexie from 'dexie';
import relationships from 'dexie-relationships';
 
export const db = new Dexie('resume', {addons: [relationships]});

db.version(1).stores({
    resume: '++id,name,title,address,email,phone',
    experience: '++id, company,country,city,designation,start,end,order_id,resumeId->resume.id,margin',
    projects: '++id, projectName, contributions, expId -> experience.id,resumeId->resume.id,margin',
    education: '++id, institution,country,city,start,end,score,degree,resumeId->resume.id,margin',
    highlights: '++id, highlightName,contributions, eduId -> education.id,resumeId->resume.id,margin',
    libraries: '++id, libraryName, level,resumeId->resume.id,margin',
    languages: '++id, languageName, level,resumeId->resume.id,margin',
    algorithms: '++id, algorithmName, level,resumeId->resume.id,margin',
    coding: '++id, codingName, level,resumeId->resume.id,margin',
    links: '++id, linkDesc, linkAddress,resumeId->resume.id,margin',
    custom: '++id, title,visibility,type,resumeId->resume.id,margin',
    custom_items: '++id,item,itemId -> custom.id,resumeId->resume.id,margin'   
});