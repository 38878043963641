import React from 'react';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../models/db";
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from '../../../context/MarginContext';
import { Slider } from '@mui/material';
import { useTheme } from '../../../context/ThemeContext';
function LinksLive() {
  const {sectionMargin,changeLinks} = useTheme();
  const {theme} = useMain();
  const {marginEdit} = useMargin();
    const links = useLiveQuery(async () =>{
      return await db.links.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
    }); 

      const LinkList = ({...item})=>{
        return <div className=" flex items-center space-x-1 ">
                <div dangerouslySetInnerHTML={{__html : item.linkAddress}} className='text-editor '>
               </div>
        </div>
      }
  return (
    <section className="w-full p-4 pl-0 text-sm" style={{marginBottom: `${sectionMargin.links}px`}}>
              <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>LINKS</h1> 
                <div className='py-2 '>
                {(links)?links?.map((item,index)=>(<LinkList key={index} {...item}/>)):<></>}
                </div>
                {(marginEdit)?(<div className={`w-full h-fit space-y-1 text-xs px-2 text-slate-700 border border-slate-200 print:hidden`}>
                 <Slider
                   onChange={(e)=>changeLinks(e.target.value)}
                  value={sectionMargin.links}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
                <p className='px-1'>Adjust bottom margin {`(${sectionMargin.links} pixels)`}</p>
              </div>):<></>}
    </section>
  )
}
export default LinksLive;