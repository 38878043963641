import React,{useState,useRef} from 'react';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useLinks } from '../../context/LinksContext';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useMain } from '../../../main/context/MainContext';

function Links() {
  const {theme} = useMain();
  const { linksVisibility,isLinksVisible,linkRef,fetchEdit,toggleLinkFormModal} = useLinks();
  
  const links = useLiveQuery(async () => {
    return await db.links.where('resumeId').equals(parseInt(localStorage.getItem('resume'))).toArray();
  }); 
  
  const deleteRecord = (id)=>{
    return db.links.delete(id);
  }

  const LinkList = ({...item})=>{
    return <div className="flex flex-col w-full p-3">
    <div dangerouslySetInnerHTML={{__html : item.linkAddress}} className='text-editor '>
    </div>
      <div className='flex justify-end space-x-2 p-2 text-xs'>
        <button onClick={()=>{fetchEdit(item.id)}} style={{backgroundColor: theme}} className={`px-2 rounded-2xl bg-color-${theme} text-white`}>
          Edit
        </button>
        <button onClick={()=>{deleteRecord(item.id)}} className='px-2 rounded-2xl bg-slate-500 hover:bg-red-300 text-white'>
          Delete
        </button>
      </div>
    </div>
  }

 

  return (
    <>
          <div ref={linkRef} className=''></div>
    <div  className="flex justify-between items-center m-4 mb-0 p-3 shadow-2xl bg-white">
            <h1 style={{color: theme}}>Links</h1>
            {
                    (isLinksVisible)?
                    <svg onClick={linksVisibility} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                       <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>:
                    <svg onClick={linksVisibility} xmlns="http://www.w3.org/2000/svg" className="text-teal-700 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  }
          </div>
          

          <div className="flex  items-center mx-4 my-0 mb-0 shadow-2xl bg-white space-x-1">
            <div className="w-full  ">
                <div className="flex flex-col flex-wrap w-full  items-center ">
                 {
                  (links)?links.map((item,index)=>(<LinkList key={index} {...item}/>)):<></>
                 }
                 <div className="flex justify-center bg-white pb-1">
                <button style={{backgroundColor: theme}} onClick={toggleLinkFormModal} className={`bg-color-${theme} text-xs text-white px-2 rounded-xl`}>ADD LINK</button>
              </div>
                </div>
            </div>
          </div> 

          
          </>
  )
}

export default Links