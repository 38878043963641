import { useModal } from '../context/ModalContext'
import React,{useContext,useState,useRef} from 'react'
import { db } from "../model/db";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useLiveQuery } from "dexie-react-hooks";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMain } from '../../main/context/MainContext';

function TaskModal(props) {
    const {toggleTaskModal,taskModal,currentSheet,completed,addTop} = useModal();
    const {dark} = useMain();
    const todos =  useLiveQuery(
      () => db.todos.where('sheetId').equals(props.value).and((item)=>{
        if (completed)
          return item.isCompleted ===false && item.primaryRoot === true;

          return item.primaryRoot === true;
      }).sortBy('orderId'),[completed]
    );

    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [brief,setBrief] = useState('');
    const titleRef = useRef();
    const noteRef = useRef();

    const changeTitle = (value)=>{
        return setTitle(value);
      }
  
      const changeNote = (value)=>{
        return setNote(value);
      }

      const changeBrief = (value)=>{
        return setBrief(value);
      }
     
      function updateOrder(id) {
        if(addTop && todos !== undefined)
        {
          var temp = todos;
          console.log(temp);
          console.log(todos);
        for (let i = 0; i < temp.length; i++) {
          if(i===temp.length-1 || i === 0)
          {
            if(i===temp.length-1)
            {
            db.todos.update(temp[temp.length-1].id,{orderId: id});
            
          }
          else {
            db.todos.update(temp[0].id,{orderId: temp[1].orderId})
          }
          }
          else{
            db.todos.update(temp[i].id,{orderId: temp[i+1].orderId})
          }
        }
      }
      }
  
      const addTask =  ()=>{
        if(title==='')
        return;
        toggleTaskModal();
        
         db.todos.add({
          "title": title,
          "note": note,
          "brief": brief,
          "createdAt": new Date(),
          "sheetId": props.value,
          "projectsId": parseInt(currentSheet),
          "isCompleted": false,
          "primaryRoot": true,
          "rootId": null,
          "children": [],
        }).then( (id)=>{
          if(!addTop)
          db.todos.update(id,{orderId: id});
          else if(todos.length)
          {
            db.todos.update(id,{orderId: todos[0].orderId});
            updateOrder(id);
          }
          else 
          db.todos.update(id,{orderId: id});
            
        });

        
        setNote('');
        setTitle('');
        setBrief('')
      }

  return (
    <>
      <Dialog open={taskModal} onClose={toggleTaskModal}>
        <DialogTitle>Add task</DialogTitle>
        <DialogContent className='overflow-y-auto space-y-1'>
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            tabIndex={1} 
            ref={titleRef} 
            name='title'
            value={title} 
            onChange={(e)=>changeTitle(e.target.value)}
          />
          <TextField
           id="brief"
           label="Brief"
           multiline
           rows={3}
           variant="standard"
           tabIndex={2}
           ref={noteRef} 
           name='note'  
           value={brief} 
           onChange={(e)=>changeBrief(e.target.value)}
           fullWidth
        />
        <div className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`}>
        <EditorToolbar toolbarId={'t1'} className={`w-full mt-2 ${dark? 'dark-mode': 'light-mode'}`} />
        <ReactQuill tabIndex={3}
          className={`${dark? 'dark-mode': 'light-mode'}`}
          theme="snow"
          value={note}
          onChange={changeNote}
          placeholder={"Details"}
          modules={modules('t1')}
          formats={formats}
         />
         </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleTaskModal}>Cancel</Button>
          <Button variant='contained' onClick={()=>addTask()}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TaskModal