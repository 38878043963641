import React from 'react';
import { db } from '../../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from '../../../context/MarginContext';
import MarginEducationEdit from '../MarginEducationEdit';


function EducationList({...edu}) {
  const {theme} = useMain();
  const {marginEdit} = useMargin();
    const highlights = useLiveQuery(async () => {
        return await db.highlights.toArray();
      }); 
  return (
    <div  style={{marginBottom: `${edu.margin}px`}}>
    <div className="flex justify-between items-center text-xs font-semibold">
       <p>{edu.degree}</p> 
        <p>{edu.city},{edu.country}</p>
    </div>
    <div className="flex flex-col text-xs space-y-1">
    <div className="font-semibold">
        <p>{edu.institution}</p>
    </div>
    <p><span className='pr-1'>Score:</span>{edu.score}</p> 
    <p>{edu.start.slice(0,-3)}<span className='px-2'>&harr;</span>{(edu.end==='Present')?edu.end:edu.end.slice(0,-3)}</p> 
    </div>
    <TransitionGroup>
    {
      highlights?.map((highlight,index)=>(
                                  (edu.id===highlight.eduId)?
                                  (
                                    <CSSTransition key={index} timeout={500} classNames="item1">
                                    <div className='my-2' key={index}><span className='px-2 py-1 text-xs bg-slate-200'>{highlight.highlightName}</span>
            <div className='my-1 font-normal text-editor editor-view' dangerouslySetInnerHTML={{ __html: highlight.contributions}}></div>
            </div>
            </CSSTransition>
            ):<></>
                                ))
    }
    </TransitionGroup>
    {(marginEdit)?<MarginEducationEdit id={edu.id} margin={edu.margin} />:<></>}
</div>
  )
}

export default EducationList;