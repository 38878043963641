import React,{useContext ,useState,useEffect } from 'react';
import { db } from '../models/db';

const CustomContext = React.createContext();

export const useCustom = ()=>{
    return useContext(CustomContext);
   }

   export const CustomProvider = ({children})=>{

    const [customEditModal, setCustomEditModal] = useState(false);
    const [editId, setEditId] = useState();
    const [content, setContent] = useState();
    const changeContent = (value)=>{
        return setContent(value)
    }
    function toggleCustomEditModal(){
        return setCustomEditModal(!customEditModal);
      }

      const fetchEdit = async(id)=>{
        const data = await db.custom_items.get(id);
        console.log(id);
        setEditId(data.id);
        setContent(data.item);
        return toggleCustomEditModal();
    }

    const submitEdit= ()=>{
        let item =content;
          db.custom_items.update(editId,{
              item
          })
          return toggleCustomEditModal();
      }
      
  
    const value = {customEditModal,toggleCustomEditModal,fetchEdit,editId,content,changeContent,submitEdit}
        return(
          <CustomContext.Provider value={value}>
              {children}
          </CustomContext.Provider>
  
        )
  }