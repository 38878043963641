import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useExperience } from '../../context/ExperienceContext';
import { useTheme } from "../../context/ThemeContext";
import { useMain } from '../../../main/context/MainContext';

function ProjectsForm() {
  const {theme} = useMain();
    const {toggleProjectsModal,addProjects,fieldProject,projectName,fieldContributions,contributions} =useExperience();
    
    const updateContributions=(value)=>{
      return fieldContributions(value);
    }
    const submitProject= async ()=>{
      return await addProjects();
    }

  return (
    <div className="modal-body">
            <div className="flex flex-col justify-center">
                <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                   Add project
                </div>
                 <form className="w-full px-16 py-4 flex justify-center flex-col">
                 <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2 mb-8`}>
                 <input onChange={(e)=>fieldProject(e.target.value)} value={projectName} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Project name"/>
                 </div>
                  <div className="light-mode">
                  <EditorToolbar className='light-mode' toolbarId={'t1'}/>
                  <ReactQuill
                   className="light-mode"
                   theme="snow"
                   value={contributions}
                   onChange={updateContributions}
                   placeholder={"List your contributions"}
                   modules={modules('t1')}
                   formats={formats}
                  />
                  </div>
                  </form>
                </div>
                  <div className="mb-28 w-full flex justify-center item-center space-x-12">
                   <button onClick={toggleProjectsModal} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                   <button style={{backgroundColor: theme}} onClick={submitProject} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Add</button>
                  </div>
        </div>
  )
}

export default ProjectsForm