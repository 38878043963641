import React,{useState} from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import { Typography } from '@mui/material';
import { db } from '../model/db';

function TabSheet(value) {
    const [sheet, setSheet] = useState('Sheet');
        db.sheets.get(parseInt(value.id)).then(function(item) {
            setSheet(item.title);
          }); 
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to='/app/todo/sheets' className='underline hover:text-teal-600'>
       <Typography>
          Sheets
       </Typography>
      </Link>
        <Typography>
          {sheet}
        </Typography>
      </Breadcrumbs>
  )
}

export default TabSheet