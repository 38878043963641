import React,{useState} from 'react'
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useCustom } from '../../context/CustomContext';
import { useMain } from '../../../main/context/MainContext';

const CustomList= ({...items})=>{
      
      const {theme} = useMain();
      const {fetchEdit} = useCustom();
      const [item, setItem] = useState('');
      const [title, setTitle] = useState('');
      const [isEdit, setIsEdit] = useState(false);

      const customItems = useLiveQuery(async () => {
        return await db.custom_items.toArray();
      }); 

      const changeItem=(value)=>{
        return setItem(value);
      }

      const changeTitle=(value)=>{
        return setTitle(value);
      }

      const changeVisibilty = async(id,isVisible)=>{
        let visibility = !isVisible;
        await db.custom.update(id,{
          visibility
        })
      }
     
      const addItem = async(id)=>{
        let itemId = id;
        
        await db.custom_items.add({
            item,itemId,resumeId: parseInt(localStorage.getItem('resume'))
        });
        return changeItem('');
      }

      const fetchTitle = (value)=>{
        setTitle(value);
        return setIsEdit(!isEdit);
      }
       const updateTitle = async(id)=>{
        if(title==="")
        return setTitle("Title Here")
        
        await db.custom.update(id,{
          title
        })
        return setIsEdit(!isEdit)
       }
      

      const deleteRecord = (id)=>{
        return db.custom_items.delete(id);
      }

      const deleteSection = (id)=>{
        return db.custom_items.where('itemId').equals(id).delete().then(
          db.custom.delete(id)
        );  
      }


      const ItemList = ({...customItem})=>{
        return <div className="flex flex-col border w-full border-b-gray-200 p-1">
        <div dangerouslySetInnerHTML={{__html : customItem.item}} className='text-editor '>
        </div>
          <div className='flex justify-end space-x-2 p-2 text-xs'>
            <button style={{backgroundColor: theme}} onClick={()=>fetchEdit(customItem.id)}  className={`px-2 rounded-2xl text-white`}>
              Edit
            </button>
            <button onClick={()=>{deleteRecord(customItem.id)}} className='px-2 rounded-2xl bg-slate-500 hover:bg-red-300 text-white'>
              Delete
            </button>
          </div>
        </div>
      }

      const TitleEdit = ()=>{
        return (
          <div className='absolute top-0 w-full h-full'>
              <div className='flex items-center space-x-2'>
                <input autoFocus="autoFocus" value={title} onChange={(e)=>(changeTitle(e.target.value))}
                 className='appearance-none rounded-xl p-2 h-6  w-36 text-gray-700 focus:outline-none border border-teal-700 text-center'/>
                <button style={{backgroundColor: theme}} onClick={()=>updateTitle(items.id)} className='px-2 text-sm text-white rounded-xl'>
                  Save
                 </button>
              </div>
            </div>
        )
      }
    return(
      <>
        <div  className="flex justify-between items-center m-4 mb-0 p-3 shadow-2xl bg-white">
          <div className='relative'>
           <h1 style={{color: theme}} onClick={()=>fetchTitle(items.title)} className={`cursor-pointer hover:border border-dashed border-slate-500`}>{items.title}</h1>
            {isEdit?<TitleEdit/>:<></>}
          </div>
        <div className='flex space-x-2'>
              <button onClick={()=>deleteSection(items.id)} className='px-2 rounded-2xl bg-slate-500 hover:bg-red-300 text-xs text-white'>Remove</button>
        {
            
                (items.visibility)?
                <svg onClick={()=>changeVisibilty(items.id,items.visibility)} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                   <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>:
                <svg onClick={()=>changeVisibilty(items.id,items.visibility)} xmlns="http://www.w3.org/2000/svg" className="text-teal-700 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              
              }
              </div>
      </div>
      
      

      <div className="justify-center  items-center m-4 mt-0 mb-0 p-3 shadow-2xl bg-white">
      <div className="light-mode">
      <EditorToolbar  className="light-mode" toolbarId={'tool'+items.id}/>
        <ReactQuill
          theme="snow"
          value={item}
          onChange={changeItem}
          placeholder={"Add your content"}
          modules={modules('tool'+items.id)}
          formats={formats}
        />
        </div>
         <div className='flex justify-center space-x-4 p-2 '>
         <button onClick={()=>changeItem("")} className='bg-slate-300 px-2 '>Clear</button>
         <button  style={{backgroundColor: theme}} onClick={()=>addItem(items.id)} className={`bg-color-${theme} text-white px-2`}>Add</button>
         </div>
      </div>

      <div className="flex  items-center m-4 mt-0 mb-0 p-3 shadow-2xl bg-white space-x-1">
        <div className="p-4 w-full  ">
            <div className="flex flex-col flex-wrap w-full  items-center ">
             {
              (customItems)?customItems.map((customItem,index)=>(
                (customItem.itemId===items.id)?<ItemList key={index} {...customItem}/>:<></>
                )):<></>
             }
            </div>
        </div>
      </div>
      </>
    );
}

export default CustomList;