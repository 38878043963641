import React from 'react';
import { useEducation } from '../../context/EducationContext';
import { db } from '../../models/db';
import { useLiveQuery } from "dexie-react-hooks";
import { useMain } from '../../../main/context/MainContext';

function EducationEditCard({...edu}) {
  const {theme} = useMain();
 const {
    toggleHighlightsModal,toggleHighlightsEditModal,deleteEducation,fetchHighlightDetails,changeHighlightId,fetchEducationDetails,toggleEducationEditModal
  } = useEducation();
  const highlights = useLiveQuery(async () => {
    return await db.highlights.toArray();
  }); 

  const setHighlightId =async(id)=>{
    
    toggleHighlightsModal();
    return await changeHighlightId(id);
  }

  const setHighlightEditId =async(id)=>{
    await fetchHighlightDetails(id);
    toggleHighlightsEditModal();
  }
 
  const setEducationId =async(id)=>{
    await fetchEducationDetails(id);
    toggleEducationEditModal();
  }
 

  const deleteHighlightItem = (id)=>{
    return db.highlights.delete(id);
  }
  return (
    <>
          <div className="border border-t-1 border-slate-200 p-2 bg-white my-2">
            <div className="flex justify-between items-center">
            <div className="items-center p-2 space-y-1 ">
              <p style={{color: theme}} className={`text-color-${theme} text-xs`}>{edu.start.slice(0,-3)}<span className='px-3'>&harr;</span> {(edu.end==='Present')?edu.end:edu.end.slice(0,-3)}</p>
              <p className='text-xs font-semibold'>{edu.degree}</p>
              <p className="w-fit rounded-xl text-xs">{edu.institution}</p>
            </div>
            <div className="flex flex-col px-3 pb-1 justify-end">
              <p className='text-xs font-semibold'>{edu.city+' '+edu.country}</p>
              <p className='text-xs font-semibold'>{edu.score}</p>
            </div>
            </div>
            
            <div className="flex flex-col mx-4">
                              {
                                highlights?.map((highlight,index)=>(
                                  (edu.id===highlight.eduId)?
                                  (<div className=" p-4 mb-1  border border-slate-300  ">
                              <div className="flex justify-between">
                                <div className='text-sm'>{highlight.highlightName}</div>
                              </div>
                              <div className='p-2 m-1 text-editor editor-view' dangerouslySetInnerHTML={{__html: highlight.contributions}}></div>
                              <div className="flex justify-end space-x-2">
                                  <button style={{backgroundColor: theme}} onClick={()=>setHighlightEditId(highlight.id)} className={`text-xs rounded-xl px-2 text-white`}>EDIT</button>
                                   <button onClick={()=>deleteHighlightItem(highlight.id)} className="text-xs rounded-xl px-2 bg-slate-500 hover:bg-red-300 text-white">DELETE</button> 
                                </div>
                              </div>):<></>
                                ))
                            }
                          </div>
                          <div className='flex justify-end space-x-2 p-1 mr-6'>
             <button style={{borderColor: theme,color: theme}} onClick={()=>setHighlightId(edu.id)} className={`text-xs rounded-xl px-2 border border-color-${theme} text-color-${theme} hover:border-white hover:text-white`}>
               ADD ACTIVITIES 
             </button>
             <button style={{backgroundColor: theme}} onClick={()=>setEducationId(edu.id)} className={`text-xs  rounded-xl px-2 hover:bg-slate-500 text-white`}>EDIT</button> 
             <button onClick={()=>deleteEducation(edu.id)} className="text-xs rounded-xl px-2 bg-slate-500 hover:bg-red-300 text-white">DELETE</button> 
            </div>
            </div>
            </> 
  )

}
export default EducationEditCard;