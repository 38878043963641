import React,{useContext ,useState,useRef } from 'react';
import  {db}  from '../models/db';

const EducationContext = React.createContext();

export const useEducation = ()=>{
    return useContext(EducationContext);
   }

   export const EducationProvider = ({children})=>{

    const [EducationModal, setEducationModal] = useState(false);
    const [EducationEditModal, setEducationEditModal] = useState(false);
    const [highlightsModal, setHighlightsModal] = useState(false);
    const [highlightsEditModal, setHighlightsEditModal] = useState(false);
    function toggleEducationModal(){
      return (EducationModal)?setEducationModal(false):setEducationModal(true);
    }

    function toggleEducationEditModal(){
      return (EducationEditModal)?setEducationEditModal(false):setEducationEditModal(true);
    }

    function toggleHighlightsModal(){
      return (highlightsModal)?setHighlightsModal(false):setHighlightsModal(true);
    }
    
    function toggleHighlightsEditModal(){
      return (highlightsEditModal)?setHighlightsEditModal(false):setHighlightsEditModal(true);
    }
    const [institution, setInstitution] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [score, setScore] = useState('');
    const [degree, setDegree] = useState('');
    const [highlightName, setHighlightName] = useState('');
    const [contributions, setContributions] = useState('');
    const [isEducationVisible, setIsEducationVisible] = useState(true);
    const inputEnd = useRef();
    const margin = 0;

    const educationVisibility = ()=>{
      return setIsEducationVisible(!isEducationVisible);
    }

    const fieldInstitution =(InstitutionValue)=>{
      return setInstitution(InstitutionValue);
    }
  
    const fieldCity =(cityValue)=>{
      return setCity(cityValue);
    }
  
    const fieldCountry =(countryValue)=>{
      return setCountry(countryValue);
    }

    const fieldDegree =(value)=>{
      return setDegree(value);
    }

    const fieldScore =(countryValue)=>{
      return setScore(countryValue);
    }
  
    const fieldStartdate =(startdateValue)=>{
      return setStart(startdateValue);
    }
  
    const fieldEnddate =(enddateValue)=>{
      return setEnd(enddateValue);
    }
  
    const fieldCurrent =(currentValue)=>{
        if(currentValue===true)
        { 
          const attr = document.createAttribute("disabled");
          inputEnd.current.setAttributeNode(attr);
          return setEnd("Present");
        }
        else
          inputEnd.current.removeAttribute("disabled");
       return  setEnd(inputEnd.current.value)
    }

    const fieldHighlight =(highlightValue)=>{
      return setHighlightName(highlightValue);
    }
  
    const fieldContributions = (data)=>{
      setContributions(data);
      console.log(data);
    }
//Edit Highlight--------------------------------------------
  const [highlightEditName, setHighlightEditName] = useState("");
  const [editContributions, setEditContributions] = useState("");

  const fieldEditHighlight =(HighlightValue)=>{
    return setHighlightEditName(HighlightValue);
  }

  const fieldEditContributions = (data)=>{
    setEditContributions(data);
  }

//Edit Part---------------------------------------------
    const [editInstitution, setEditInstitution] = useState("");
    const [editCity, setEditCity] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [editScore, setEditScore] = useState("");
    const [editDegree, setEditDegree] = useState("");
    const [editStart, setEditStart] = useState();
    const [editEnd, setEditEnd] = useState();
    const [editCurrent, setEditCurrent] = useState();
    const inputEditEnd = useRef();



    const fieldEditInstitution =(InstitutionValue)=>{
      return setEditInstitution(InstitutionValue);
    }
  
    const fieldEditCity =(cityValue)=>{
      return setEditCity(cityValue);
    }
  
    const fieldEditCountry =(value)=>{
      return setEditCountry(value);
    }
  
  
    const fieldEditStartdate =(startdateValue)=>{
      return setEditStart(startdateValue.toString());
    }
  
    const fieldEditDegree =(value)=>{
      return setEditDegree(value);
    }

    const fieldEditScore =(value)=>{
      return setEditScore(value);
    }
    const fieldEditEnddate =(enddateValue)=>{
      return setEditEnd(enddateValue);
    }
  
    const fieldEditCurrent =(currentValue)=>{
        if(currentValue===true)
        { 
          const attr = document.createAttribute("disabled");
          inputEditEnd.current.setAttributeNode(attr);
          return setEditEnd("Present");
        }
        else
          inputEditEnd.current.removeAttribute("disabled");
       return  setEditEnd(inputEnd.current.value)
    }
//---------------------------------------------------------
    function closeEducationModal(){
      return toggleEducationModal();
    }

    const clearEducationForm= ()=>{
      setInstitution('');
      setCountry('');
      setCity('');
      setStart('');
      setEnd('');
      setScore('');
      setDegree('');
    }
    //Add Education
    const addEducation= async ()=>{
      try {
        const id = await db.education.add({
          institution,country,city,start,end,degree,score,margin,resumeId: parseInt(localStorage.getItem('resume'))
        }); 
        toggleEducationModal();
        clearEducationForm();
      } catch (error) {
        console.error(error);
      }
    }

    //Delete Education

    const deleteEducation =async (id)=>{
     return db.education.delete(id)
    .then( () =>{
     db.highlights.where('eduId').anyOf(id).delete()
    });
    }
    const [eduId, setEduId] = useState();

    const changeHighlightId =(id)=>{
      console.log(id);
      return setEduId(id);
    }

    const [highlightEditId, setHighlightEditId] = useState();
    const changeHighlightEditId = (id)=>{
      setHighlightEditId(id);
    }

    const fetchEducationDetails =async(id)=>{
       const data = await db.education.get(id)
       setEduId(data.id)
      setEditInstitution(data.institution);
      setEditCity(data.city);
      setEditCountry(data.country);
      setEditStart(data.start);
      setEditEnd(data.end);
      setEditCurrent(data.current);
      setEditDegree(data.degree);
      setEditScore(data.score);
    }

    const fetchHighlightDetails =async(id)=>{
      const data = await db.highlights.get(id);
      setHighlightEditId(id)
      setHighlightEditName(data.highlightName);
      setEditContributions(data.contributions);
   }
    const updateEducation = async()=>{
       await db.education.update(eduId,{
        institution: editInstitution,
        city: editCity,
        country: editCountry,
        start: editStart,
        end: editEnd,
        score: editScore,
        degree: editDegree
      })

      return toggleEducationEditModal();
    }

    const updateHighlights= async()=>{
      await db.highlights.update(highlightEditId,{
       highlightName: highlightEditName,
       contributions: editContributions
     })

     return toggleHighlightsEditModal();
   }
    const addHighlights = async()=>{
      
        return await db.highlights.add({
          highlightName,contributions,eduId
        }).then(()=>{
          setHighlightName('');
          setContributions('');
          toggleHighlightsModal()
        }).error((error)=>{
          console.error(error);
        })
    }
    
    const value = {
      toggleEducationModal,EducationModal,EducationEditModal,addEducation,closeEducationModal,deleteEducation,
      fieldStartdate,fieldEnddate,fieldCurrent,inputEnd,country,city,start,end,institution,fieldCity,fieldCountry,fieldInstitution,fetchEducationDetails,
      toggleEducationEditModal,fieldEditInstitution,fieldEditCity,fieldEditCountry,fieldEditStartdate,fieldEditEnddate,
    fieldEditCurrent,inputEditEnd,editInstitution,editCity,editCountry,editStart,editEnd,editCurrent,updateEducation,eduId,
    fieldHighlight,addHighlights,toggleHighlightsModal,highlightsModal,changeHighlightId,isEducationVisible,educationVisibility,fieldContributions,contributions,fieldEditHighlight,highlightName,
    fetchHighlightDetails,changeHighlightEditId,highlightsEditModal,toggleHighlightsEditModal,fieldEditContributions,editContributions,updateHighlights,highlightEditName,fieldDegree,degree,fieldScore,score,fieldEditScore,editScore,editDegree,fieldEditDegree
     }

      return(
        <EducationContext.Provider value={value}>
            {children}
        </EducationContext.Provider>

      )
}
