import React,{useEffect,useState} from 'react';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from '../../../models/db';
import { useMain } from '../../../../main/context/MainContext';
import { useMargin } from '../../../context/MarginContext';
import { Slider } from '@mui/material';
import { useTheme } from '../../../context/ThemeContext';

function LanguageLive() {
  const {sectionMargin,changeLanguages} = useTheme();
  const {theme} = useMain();
  const {marginEdit} = useMargin();
  const [advancedCount, setAdvancedCount] = useState(0);
  const [beginnerCount, setBeginnerCount] = useState(0);
  const [intermediateCount, setIntermediateCount] = useState(0);
  
  const advanced = useLiveQuery(async () => {
    return await db.languages.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  const intermediate = useLiveQuery(async () => {
    return await db.languages.where('level').equals('Intermediate').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  const beginner = useLiveQuery(async () => {
    return await db.languages.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  useEffect(() => {
    db.languages.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume')) 
    }).count((count)=>{
      setAdvancedCount(count);
    })
    
  }, [advanced]);

  useEffect(() => {
    db.languages.where('level').equals('Intermediate').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume')) 
    }).count((count)=>{
      setIntermediateCount(count);
    })
    
  }, [intermediate]);
  
  useEffect(() => {
    db.languages.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume')) 
    }).count((count)=>{
      setBeginnerCount(count);
    })
    
  }, [beginner]);

  const LanguageList = ({count,value,items})=>{
     return (value === count-1)?<div className='px-1 text-xs'>{items.languageName}</div>:<div className='px-1 text-xs'>{items.languageName+','}</div>;
  }
  return (
    <section className="w-full p-4 pl-0" style={{marginBottom: `${sectionMargin.languages}px`}}>
       <h1 style={{borderColor: theme}} className='font-bold text-2xl border-b-4 w-fit mb-4'>LANGUAGES</h1>
              <div className='flex-container py-2 '>
                { (advancedCount>0)?(
                    <div className="w-full  ">
                    <h1 className='text-xs font-semibold uppercase'>Advanced</h1>
                   <div className='flex-container'>
                   {advanced?advanced.map((item,index)=>(<LanguageList key={index} count={advancedCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                <div className='flex-container py-2 '>
                { (intermediateCount>0)?(
                    <div className="w-full  ">
                    <h1 className='text-xs font-semibold uppercase'>Intermediate</h1>
                   <div className='flex-container'>
                   {intermediate?intermediate.map((item,index)=>(<LanguageList key={index} count={intermediateCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                <div className='flex-container py-2 '>
                { (beginnerCount>0)?(
                    <div className="w-full  ">
                    <h1 className='text-xs font-semibold uppercase'>Beginner</h1>
                   <div className='flex-container'>
                   {beginner?beginner.map((item,index)=>(<LanguageList key={index} count={beginnerCount} value={index} items={item}/>)):<></>}
                   </div>
                </div>):<></>
                  }
                </div>
                {(marginEdit)?
                (<div className='w-full h-fit space-y-1 text-slate-700 text-xs p-1 print:hidden'>
                 <p>Adjust bottom margin {`(${sectionMargin.languages} pixels)`}</p>
                  <Slider
                   onChange={(e)=>changeLanguages(e.target.value)}
                  value={sectionMargin.languages}
                  min={0}
                  max={500}
                  valueLabelDisplay="auto"
                  />
              </div>):<></>
              }
    </section>
  )
}

export default LanguageLive;