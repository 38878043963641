import { useForm } from "../../context/FormContext";
import { useMargin } from "../../context/MarginContext";
import { useTheme } from "../../context/ThemeContext";
import { Slider } from '@mui/material';
import { db } from "../../models/db";
import { useLiveQuery } from "dexie-react-hooks";
function PersonalDetailsLive() 
{
  const {sectionMargin,changePersonal } = useTheme();
  const {marginEdit} = useMargin();
  const resume = useLiveQuery(() => db.resume.get(parseInt(localStorage.getItem('resume'))));
  return (
     <section className="p-2 m-2 " style={{marginBottom: `${sectionMargin.personalDetails}px`}} >
         {(resume)?<h1 className="text-xl font-bold">{(resume?.name==='')? "Name": resume?.name}</h1>:<h1 className="text-xl font-bold">Name</h1>}
         {(resume)?<h1 className="text-sm">{(resume?.title==='')?'Describe yourself': resume?.title}</h1>:<p className="text-xs">Describe yourself</p>}
         {(marginEdit)?(<div className={`w-full h-fit px-2 space-y-1 text-xs text-slate-700 border border-slate-200 print:hidden`}>
           <Slider
           onChange={(e)=>changePersonal(e.target.value)}
           value={sectionMargin.personalDetails}
           min={0}
           max={500}
           valueLabelDisplay="auto"
           />
          <p>Adjust bottom margin {`(${sectionMargin.personalDetails} pixels)`}</p>
         </div>):<></>}
     </section>
  )
}

export default PersonalDetailsLive