import React from 'react';
import { useHistory } from 'react-router-dom';

function Landing() {
  const history = useHistory();
  const handleClick = (path) => {
    history.push(path);
  };
  return (
    <div class="bg-gray-100 font-sans">
  <nav class="bg-white py-6 shadow">
    <div class="container ml-8 px-4">
      <a href="/" class="text-4xl font-bold text-gray-600">Acad<span class="text-teal-600">Utils</span><span class="text-xs">.com</span></a>
    </div>
  </nav>

  <section class="py-20">
    <div class="container mx-auto px-4">
      <div class="max-w-5xl mx-auto">
        <h1 class="text-4xl font-semibold text-gray-900 mb-6 lg:w-1/2">Create a Professional Resume in Minutes</h1>
        <p class="text-gray-700 text-lg mb-8 lg:w-1/2">With our easy-to-use resume builder app, you can create a professional-looking resume that will help you stand out from the crowd.</p>
        <a onClick={()=>handleClick('/app/resume/home')} class="bg-teal-500 text-white py-3 px-6 rounded hover:bg-teal-600 transition-all duration-200">Create Your Resume</a>
      </div>
    </div>
  </section>

  <section class="py-20 bg-gray-200">
    <div class="container mx-auto px-4">
      <div class="max-w-5xl mx-auto">
        <h2 class="text-2xl font-semibold text-gray-900 mb-6">Choose the Right Plan for You</h2>
        <p class="text-gray-700 text-lg mb-8 ">We offer three different subscription plans to integrate our app into your website:</p>

        <div class="flex flex-col md:flex-row justify-center space-y-4 md:space-x-4 w-full">
          <div class="bg-white shadow-md shadow-teal-600 p-8 rounded-lg w-1/3">
            <h3 class="text-xl font-semibold text-gray-600 mb-4">Free</h3>
            <div class="list-none  text-sm mb-6 space-y-2">
              <div><span class="text-teal-600 font-semibold">Watermark/Logo:</span><br/> The resumes created with the Free Plan will have a watermark or logo that links back to our website.</div>
              <div><span class="text-teal-600 font-semibold">Editability: </span><br/>Users will have limited editing capabilities for their websites.</div>
              <div><span class="text-teal-600 font-semibold">Price: </span><br/>This plan is available for <span class="underline text-teal-500 px-1">free</span>, allowing users to add resume builder to their websites without any cost.</div>
            </div>
            <a onClick={()=>handleClick('/app/resume/home')} class="bg-teal-500 text-white py-3 px-6 rounded hover:bg-teal-600 transition-all duration-200">Get Started</a>
          </div>

          <div class="bg-white shadow-md hover:shadow-teal-600 p-8 rounded-lg w-1/3">
            <h3 class="text-xl font-semibold text-gray-600 mb-4">Base</h3>
            <div class="list-none  text-sm mb-6 space-y-2">
                <div><span class="text-teal-600 font-semibold">Watermark/Logo:</span><br/> Watermark/Logo: The Base Plan removes the watermark or logo from the app, providing a professional look.</div>
                <div><span class="text-teal-600 font-semibold">Editability: </span><br/>Users will have full editing capabilities, allowing them to customize and modify their websites as needed.</div>
                <div><span class="text-teal-600 font-semibold">Price: </span><br/>The Base Plan is priced at <span class="underline text-teal-500 px-1">Rs. 1000 </span>per year, providing users with enhanced features and flexibility.</div>
                </div>
              </div>

              <div class="bg-white shadow-md hover:shadow-teal-600 p-8 rounded-lg w-1/3">
                <h3 class="text-xl font-semibold text-gray-600 mb-4">Premium</h3>
                <div class="list-none  text-sm mb-6 space-y-2">
                    <div><span class="text-teal-600 font-semibold">Customization:</span><br/>  The Premium Plan offers advanced customization options, allowing users to tailor their resume builder app to their specific needs.</div>
                    <div><span class="text-teal-600 font-semibold">Price: </span><br/>For pricing details and customizations, users are encouraged to contact your team directly. The Premium Plan offers a personalized experience with tailored pricing based on individual requirements</div>
                    </div>
                    <a onClick={()=>handleClick('/app/contact')} class="bg-teal-500 text-white py-3 px-6 rounded hover:bg-teal-600 transition-all duration-200">Contact</a>
                  </div>  
    </div>
    </div>
    </div>
    </section>
    <footer>
        <div class="text-center text-slate-400 text-sm">
            &copy; 2023 AcadUtils. All rights reserved.
        </div>
    </footer>
    </div>
  )
}

export default Landing