import React, { useState, useEffect } from 'react'
import { db } from "../model/db";
import { useLiveQuery } from "dexie-react-hooks";
import ProjectsAdd from '../components/ProjectsAdd';
import ProjectsList from '../components/ProjectsList';
import NavBar from '../components/NavBar';
import { Typography } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';

function Projects() {
  
  const [projectCount, setProjectCount] = useState(0);

  const projects =  useLiveQuery(
    () => db.projects.reverse().toArray()
  );

  useEffect(() => {
    db.projects.count((count)=>{
      setProjectCount(count)
    })
  }, [projects]);

  return (
    <>
    <NavBar/>
    <div className='max-h-screen mx-8 lg:mx-36 my-24'>
      <Typography variant='h4'>
        Projects
      </Typography>

      <ProjectsAdd/>

      {(projectCount>0)?<ProjectsList/>:
              <div className='flex flex-col items-center justify-center'>
                <PostAddIcon />
                <p className=''>No projects added</p>
              </div>
              } 
    </div>
    </>
  )
}

export default Projects
