import React,{useContext ,useState,useEffect } from 'react';

const ResumeContext = React.createContext();

export const useResume = ()=>{
    return useContext(ResumeContext);
   }

   export const ResumeProvider = ({children})=>{

    useEffect(() => {
      if (localStorage.getItem('resume') === null) {
        localStorage.setItem('resume', '');
        }
    });
    const [resumeId, setResumeId] = useState();
    const changeResume = (id)=>{
      setResumeId(id)
      localStorage.setItem('resume', id);
      return setResumeId(localStorage.getItem('resume'))
    }

    const value = {resumeId,changeResume}

      return(
        <ResumeContext.Provider value={value}>
            {children}
        </ResumeContext.Provider>

      )
}
