import React from 'react'
import { db } from "../model/db";
import { exportDB } from "dexie-export-import";
import download from 'downloadjs';
import UploadIcon from '@mui/icons-material/Upload';
import { IconButton, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


function DownloadJson() {

    const downloadTodo = async(el)=>{
        try {
            const blob = await exportDB(db,{prettyJson: true});
             download(blob, `todo.json`, "application/json");
            
          } catch (error) {
            console.error(error);
          }
    }

  return (
      <MenuItem onClick={downloadTodo}>
          <ListItemIcon>
            <UploadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export</ListItemText>
        </MenuItem>   
  )
}

export default DownloadJson;