import Dexie from 'dexie';
import relationships from 'dexie-relationships';

export const db = new Dexie('todo',{addons: [relationships]});

db.version(1).stores({
  projects: '++id, title, createdAt', 
  sheets: '++id, title, createdAt,projectsId->projects.id',
  todos:'++id, title,note,brief,isCompleted,createdAt,children,primaryRoot,projectsId->projects.id,sheetId->sheets.id,rootId,orderId',
  // todomap:'++id, title,note,brief,isCompleted,sheetId->sheets.id,createdAt,children'
});
