import React,{useState,useEffect} from 'react'
import { db } from '../model/db';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import { Typography } from '@mui/material';


function TabProject(value) {
        const [project, setProject] = useState('Project');
        db.projects.get(parseInt(value.id)).then(function(item) {
            setProject(item.title);
          });   
   
  return (
    <Breadcrumbs aria-label="breadcrumb">
        <Link  to="/app/todo/projects" className='underline hover:text-teal-600'>
        <Typography>
          Projects
        </Typography>
        </Link>
        <Typography>
          {project}
        </Typography>
      </Breadcrumbs>
  )
}

export default TabProject