import React from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import { useEducation } from '../../context/EducationContext';
import { useMain } from '../../../main/context/MainContext';

function HighlightEditForm() {
  const {theme} = useMain();
    const {toggleHighlightsEditModal,fieldEditHighlight,fieldEditContributions,updateHighlights,editContributions,highlightEditName} =useEducation();

    const updateContributions=(value)=>{
      return fieldEditContributions(value);
    }

    const submitHighlight= async ()=>{
      return await updateHighlights();
    }
  return (
    <div className="modal-body">
            <div className="flex flex-col justify-center">
                <div style={{color: theme}} className={`m-2 p-2 text-color-${theme} text-2xl border-b border-gray-200`}>
                   Edit activites
                </div>
                 <form className="w-full px-16 py-4 flex justify-center flex-col">
                 <div style={{borderColor: theme}} className={`flex items-center border-b border-color-${theme} py-2 mb-4`}>
                  <input onChange={(e)=>fieldEditHighlight(e.target.value)} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Title" value={highlightEditName}/>
                 </div>
                 <div className='light-mode'>
                 <EditorToolbar className='light-mode' toolbarId={'t1'}/>
                 <ReactQuill
                   className='light-mode'
                   theme="snow"
                   value={editContributions}
                   onChange={updateContributions}
                   placeholder={"Activities"}
                   modules={modules('t1')}
                   formats={formats}
                 />
                 </div>
                  </form>
                </div>
                  <div className="mb-28 w-full flex justify-center item-center space-x-12">
                  <button onClick={toggleHighlightsEditModal} className="px-4 bg-slate-300 rounded-sm text-xl w-24 ">Cancel</button>
                  <button style={{backgroundColor: theme}} onClick={submitHighlight} className={`px-4 bg-color-${theme} rounded-sm text-white text-xl w-24`}>Save</button>
                  </div>
        </div>
  )
}

export default HighlightEditForm