import React,{useState,useEffect} from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Fade, IconButton, Paper, Typography } from '@mui/material';
import { useMain } from '../../../main/context/MainContext';

const Content = (props)=>{
   
    useEffect(() => {
      window.addEventListener('keydown',(e)=>{
        if(e.code === 'Escape')
         return props.changeStatus();
      })
    });
    return (
    <Paper sx={{backgroundColor: 'text.primary'}} className='flex flex-col space-y-4 mx-4 p-4'>
     <Box className='flex items-center'>
      <IconButton>
        <InfoOutlinedIcon color='primary'/>
      </IconButton>
      <Box sx={{color: 'grey.300',typography: 'h6'}}>
        Notice!
      </Box>
     </Box>
     <Box className='flex flex-col space-y-4 ml-4' sx={{color: 'grey.300', typography: 'body2'}}>
      <p>Click <span className='px-1 py-0.5 rounded-lg text-xs border border-1'>Preview</span> button at the right bottom to preview out and print resume.</p>
      <p>Click <span className='px-1 py-0.5 rounded-lg text-xs border border-1'>Margin</span> button to adjust the vertical bottom margin of each section.</p>
      <p>Dark mode won't work with resume builder.</p>
     </Box>
     <Box className='flex justify-end mr-4'>
      <Button onClick={()=>{props.changeStatus()}} variant='contained' color='primary'>OK</Button>
     </Box>
    </Paper>
    )
}
function InfoBar() {
    const {changeStatus,status} = useMain();
  return (
    <Box  className='fixed bottom-12 w-[100%] lg:w-[50%] z-50'>
     {
     status?   
     <Content changeStatus={changeStatus}/>
     :
     <></> 
     }
    </Box>
  )
}

export default InfoBar