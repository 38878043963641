import React from 'react';
import PreviewButton from './PreviewButton';
import { useMain } from '../../../main/context/MainContext';
import Resume1 from './Resume1';
import Resume2 from './Resume-2/Resume2';
function Resume() {
  
  const {layout} = useMain();
  
  return (
    <>
    <div className="resume absolute top-10 right-0 hidden md:flex w-1/2 mt-4 mr-2 shadow-4xl bg-white min-h-screen  ">
    {
      layout==='resume1'?<Resume1/>:<Resume2/>
    }
    </div>
    <PreviewButton/>
    </>
  )
}

export default Resume;