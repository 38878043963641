import React,{useContext ,useState ,useRef} from 'react';
import { db } from "../models/db";

const LinksContext = React.createContext();

export const useLinks = ()=>{
    return useContext(LinksContext);
   }

   export const LinksProvider = ({children})=>{

    const [isLinksVisible, setIsLinksVisible] = useState(true);
    const [linkAddress, setLinkAddress] = useState('');
    const [linkDesc, setLinkDesc] = useState('');
    const [linkModal, setLinkModal] = useState(false);
    const [linkFormModal, setLinkFormModal] = useState(false);
    const [editId, setEditId] = useState('');
    const linkRef = useRef();
    
  
    function toggleLinkFormModal(){
      return setLinkFormModal(!linkFormModal);
    }

    function toggleLinkModal(){
      return (linkModal)?setLinkModal(false):setLinkModal(true);
    }
  const linksVisibility = ()=>{
    return setIsLinksVisible(!isLinksVisible);
  }

  const addLink = async()=>{
    if(linkAddress===""|| linkAddress==="<p><br></p>")
    return;
      await db.links.add({
      linkDesc,linkAddress,resumeId: parseInt(localStorage.getItem('resume'))
     });
     
     setLinkAddress('')
     linkRef.current.scrollIntoView({
      behavior: 'smooth'
     })
     return toggleLinkFormModal();
  }
 
  const fetchEdit= async(id)=>{
    setEditId(id);
    const data = await db.links.get(editId);
    setLinkAddress(data.linkAddress);
    return toggleLinkModal();
  }

  const submitEdit = async()=>{
    await db.links.update(editId,{
        linkAddress:linkAddress
      });
      setLinkAddress("");
      return toggleLinkModal();
}
  const changeLinkAddress = (value)=>{
      console.log(value);
      return setLinkAddress(value)
  }

    
    const value = {
      toggleLinkFormModal,linkFormModal,linksVisibility,isLinksVisible,linkAddress,linkDesc,changeLinkAddress,addLink,linkRef,linkModal,toggleLinkModal,fetchEdit,submitEdit
       }
  
        return(
          <LinksContext.Provider value={value}>
              {children}
          </LinksContext.Provider>
  
        )
  }
