import React,{useContext ,useState } from 'react';

const MarginContext = React.createContext();

export const useMargin = ()=>{
    return useContext(MarginContext);
   }

   export const MarginProvider = ({children})=>{

   const [marginEdit, setMarginEdit] = useState(false);
    const toggleMarginEdit = ()=>{
        return setMarginEdit(!marginEdit);
    }

    const value = {
        toggleMarginEdit,marginEdit
       }
  
        return(
          <MarginContext.Provider value={value}>
              {children}
          </MarginContext.Provider>
  
        )
  }
