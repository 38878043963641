import React,{useEffect,useState} from 'react';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from '../../models/db';
import { useMain } from '../../../main/context/MainContext';
import { useMargin } from '../../context/MarginContext';
import { Slider } from '@mui/material';
import { useTheme } from '../../context/ThemeContext';

function AlgorithmsLive() {
    const [advancedCount, setAdvancedCount] = useState(0);
  const [beginnerCount, setBeginnerCount] = useState(0);
  const [intermediateCount, setIntermediateCount] = useState(0);
  
  const {sectionMargin,changeAlgorithm} = useTheme();
  const {theme} = useMain();
  const {marginEdit} = useMargin();

  const advanced = useLiveQuery(async () => {
    return await db.algorithms.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  const intermediate = useLiveQuery(async () => {
    return await db.algorithms.where('level').equals('Intermediate').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  const beginner = useLiveQuery(async () => {
    return await db.algorithms.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).toArray();
  }); 

  useEffect(() => {
    db.algorithms.where('level').equals('Advanced').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setAdvancedCount(count);
    })
    
  }, [advanced]);

  useEffect(() => {
    db.algorithms.where('level').equals('Intermediate').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setIntermediateCount(count);
    })
    
  }, [intermediate]);
  
  useEffect(() => {
    db.algorithms.where('level').equals('Beginner').and((item)=>{
      return item.resumeId === parseInt(localStorage.getItem('resume'))
    }).count((count)=>{
      setBeginnerCount(count);
    })
    
  }, [beginner]);

  const AlgorithmList = ({...list})=>{
     return <div className='break-all text-xs'>{list.algorithmName}</div>;
  }
  return (
    <section className="p-2  w-full" style={{marginBottom: `${sectionMargin.algorithm}px`}}>
    <div style={{borderColor: theme}}
      className={`border-l-2 mb-2`}>
        <h1 className="bg-slate-300 w-fit p-0.5">ALGORITHMS</h1>
      </div>

    <div className='flex-container py-2 '>
      { (advancedCount>0)?(
          <div className="w-full  ">
         <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Advanced</h1>
         <div className='flex flex-col'>
         {advanced?advanced.map((item,index)=>(<AlgorithmList key={index} {...item}/>)):<></>}
         </div>
      </div>):<></>
        }
      </div>
      <div className='flex-container py-2 '>
      { (intermediateCount>0)?(
          <div className="w-full  ">
         <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Intermediate</h1>
         <div className='flex-container'>
         {intermediate?intermediate.map((item,index)=>(<AlgorithmList key={index} {...item}/>)):<></>}
         </div>
      </div>):<></>
        }
      </div>
      <div className='flex-container py-2 '>
      { (beginnerCount>0)?(
          <div className="w-full  ">
         <h1 style={{color: theme}} className={`text-color-${theme} text-l font-bold mb-1`}>Beginner</h1>
         <div className='flex-container'>
         {beginner?beginner.map((item,index)=>(<AlgorithmList key={index} {...item}/>)):<></>}
         </div>
      </div>):<></>
        }
      </div>
      {(marginEdit)?
                (<div className='w-full h-fit space-y-1 text-slate-700 text-xs  p-1 print:hidden'>
                 <p>Adjust bottom margin {`(${sectionMargin.algorithm} pixels)`}</p>
                  <Slider
                          onChange={(e)=>changeAlgorithm(e.target.value)}
                          value={sectionMargin.algorithm}
                          min={0}
                          max={500}
                          valueLabelDisplay="auto"
                        />
              </div>):<></>
              }
</section>
  )
}

export default AlgorithmsLive;