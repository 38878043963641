import React from 'react'

function Contact() {
  return (
    <div class="bg-gray-100">
  <nav class="bg-white py-6 shadow">
    <div class="container mx-auto px-4">
      <a href="/" class="text-4xl font-bold text-gray-600">Acad<span class="text-teal-600">Utils</span><span class="text-xs">.com</span></a>
    </div>
  </nav>

  <header class="bg-gradient-to-r from-teal-500 to-teal-600 py-12">
    <div class="container mx-auto px-6">
      <h1 class="text-4xl font-bold text-white mb-2">Contact Us</h1>
      <h2 class="text-2xl font-semibold text-gray-200 mb-8">Have questions? We're here to help!</h2>
    </div>
  </header>

  <section class="container mx-auto px-6 py-12">
  <div class="max-w-lg mx-auto bg-white shadow p-8 rounded-lg">
      <h2 class="text-2xl font-bold text-gray-500 mb-6">Contact Information</h2>
      <div class="mb-4">
        <h3 class="text-lg font-bold text-gray-500 mb-2">Email</h3>
        <p class="text-gray-700">mgokulr@gmail.com</p>
      </div>
      <div class="mb-4">
        <h3 class="text-lg font-bold text-gray-500 mb-2">Phone</h3>
        <p class="text-gray-700">+91 9072829639</p>
      </div>
      <div class="mb-4">
        <h3 class="text-lg font-bold text-gray-500 mb-2">Social Media</h3>
        <p class="text-gray-700">
          <a class="text-blue-500 hover:text-blue-600" href="https://twitter.com/acadutils">Twitter</a>
          <br/>
          <a class="text-blue-500 hover:text-blue-600" href="https://www.reddit.com/user/acadutils">Reddit</a>
        </p>
      </div>
    </div>
  </section>
    </div>
  )
}

export default Contact