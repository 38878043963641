import React,{useState} from 'react';
import { useModal } from '../context/ModalContext';
import { db } from "../model/db";
import { useLiveQuery } from "dexie-react-hooks";
import { Paper, Button, Checkbox, IconButton, Tooltip, Typography, Box, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function SubTaskList({childId}) {
  const { toggleChildTaskModal,changeSubChildTask,changeEditId,toggleTaskView,completed} = useModal();
  const [children, setChildren] = useState([]);
  const [details, setDetails] = useState(false);
  const [more, setMore] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  
  const toggleDeleteModal = ()=>{
    return setDeleteModal(!deleteModal);
  }

  const todos =  useLiveQuery(
    () => db.todos.where('id').equals(childId).and((item)=>{
      if (completed)
        return item.isCompleted ===false;

        return item;
    }).toArray(),[completed]
  );

  const changeDetails = ()=>{
    return setDetails(!details);
  }
  
  const updateTask = async(id,e)=>{
    return await db.todos.update(id,{isCompleted: !!e.target.checked})
  }

  const viewTask = async(id)=>{
    const data = await db.todos.get(id);
    changeEditId(id,data);
    toggleTaskView();
  }
  
  //Function to delete root task and remove id from parent elements child list
  const deleteRoot = async (id,rootId,primaryRoot)=>{
    if(primaryRoot)
    {
      const rootItem = await db.todos.get(rootId);
      const childrenArray = rootItem.children;
      var index = childrenArray.indexOf(id);
      if (index >= 0) {
       childrenArray.splice( index, 1 );
      }
      db.todos.delete(id);
      db.todos.update(rootId,{children: childrenArray})
      return;
     }
      const rootItem = await db.todos.get(rootId);
      const childrenArray = rootItem.children;
      var index = childrenArray.indexOf(id);
      if (index >= 0) {
       childrenArray.splice( index, 1 );
      }
      db.todos.delete(id);
      db.todos.update(rootId,{children: childrenArray})
      return;
  }

  const deleteChildren = ()=>{
    children.map(async(item)=>{
       await db.todos.delete(item)
    })
  }

  //Recusrsive function to accumalate an array of 'id' values of child tasks to be deleted
  const subTodo = async (tasks)=>{
    for (let i = 0; i < tasks.length; i++) {
      children.push(tasks[i]);  
      let childTask = await db.todos.get(tasks[i]);
      if(childTask.children.length>0)
          subTodo(childTask.children);
   }
   }

   const deleteTask = async (tasks,id,rootId,primaryRoot)=>{
      toggleDeleteModal();
      for (let i = 0; i < tasks.length; i++) {
         children.push(tasks[i]);
         let childTask = await db.todos.get(tasks[i]);
         if(childTask.children.length)
           subTodo(childTask.children);
      }
      deleteChildren();
      deleteRoot(id,rootId,primaryRoot);
      setChildren([]);
   }

  const addChildren = (root)=>{
    changeSubChildTask(root);
    toggleChildTaskModal();
  }

  
    const DeleteDialog = ({...todo})=>{
      return (
        <Dialog
        open={deleteModal}
        onClose={toggleDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete task?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Deleting task will also delete all it's sub tasks!<br/>
              Can not undo .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteModal}>Cancel</Button>
          <Button color='error' onClick={()=>deleteTask(todo.children,todo.id,todo.rootId,todo.primaryRoot)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      )
    }

  return (
    <>
      {
        todos?.map((todo,index)=>{
         return <Box key={todo.id} className="pl-10  w-full">
           <Paper variant='outlined' className='w-full'>
            <Box className='flex flex-col  md:flex-row lg:flex-row md:justify-between lg:justify-between'>
             <Box className='flex items-center px-4 py-4'>
              <Box className='flex lg:items-center space-x-2 w-full'>
               <Checkbox onChange={(e)=>updateTask(todo.id,e)} checked={todo.isCompleted} size='small' />
                <Box onClick={()=> changeDetails()} className='w-full cursor-pointer'>
                 <Typography className={`${todo.isCompleted && 'line-through'}`}>
                   {todo.title}
                  </Typography>
                  <Typography variant='subtitle2' className={`${todo.isCompleted && 'line-through'} ${!details && 'truncate w-48'} ${details && 'line-wrap w-full'}`}>
                    {todo.brief}
                  </Typography>
                 </Box>
              </Box>
             </Box>
             <Box className='px-4 py-4'>
                <Box className='flex md:justify-end lg:justify-end w-full space-x-1'>
                <Tooltip title='Edit task'>
                    <IconButton onClick={()=> viewTask(todo.id)}  aria-label="edit task" component="label">
                      <ModeEditOutlineOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete task'>
                    <IconButton onClick={toggleDeleteModal}  aria-label="delete task" component="label">
                      <DeleteOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Add sub task'>
                 <Button onClick={()=> addChildren(todo.id)} size="small">ADD SUBTASK</Button>
                </Tooltip>
                    <IconButton aria-label="Show child tasks" component="label">
                    {
                     (todo.children.length>0)?
                      ((more)?
                        <ExpandMoreIcon onClick={()=> setMore(!more)} />:
                        <ArrowForwardIosIcon onClick={()=> setMore(!more)} />):
                        <div className='w-5 h-5'></div>
                     }
                    </IconButton>
                </Box>
               </Box>
            </Box>
             {
                (details)?
                <Paper variant='outlined' className='p-10 bg-slate-100 '>
                  <Box className='note'  dangerouslySetInnerHTML={{__html: todo.note}}></Box>
                </Paper>:
                <></>
              }
           </Paper>
            <Box className={`${!more ? 'hidden' : ''}`}>
            {
              todo.children?.map((childId,index)=>
              {
                return <SubTaskList key={childId} childId={childId} />
              })
            }
            </Box>
             <DeleteDialog {...todo}/>
          </Box>
        })
      }
    </>
  )
}

export default SubTaskList;

