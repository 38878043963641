import React from 'react'
import { Pencil, XCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useMargin } from '../../context/MarginContext';
import { useMain } from '../../../main/context/MainContext';


function PrintButton() {
    const {theme} = useMain();
    const {marginEdit,toggleMarginEdit} = useMargin();
  return (
    <div className='print-button print:hidden'>
        <div style={{backgroundColor: theme}} className={`flex items-center justify-center space-x-1 w-[30%] lg:w-[10%] h-6 px-2 py-0.5 text-white text-xs bg-color-${theme} bg-slate-500 shadow-xl rounded-full `}>
          <button onClick={toggleMarginEdit} 
           className={`w-1/2 flex justify-center items-center hover:text-slate-800 border-r border-slate-50 `}>
             {
              (marginEdit)?
             <div className='flex justify-center items-center space-x-1 text-slate-300'>
              <XCircle/>
              <p>Close</p>
             </div>:
             <div className='flex justify-center items-center space-x-1'>
             <Pencil/>
             <p>Margin</p>
             </div>
             }
          </button>
          <button className='w-1/2 hover:text-slate-800'>
          <Link to='/app/resumebuilder'>Editor</Link>
          </button>
        </div>
    </div>
  )
}

export default PrintButton